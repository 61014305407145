import React from "react";
import { AdminTopNavigationProps } from "./admin-top-navigation";
type ContainerProps = {
	children: React.ReactNode;
};

export const AdminBodyContentContainer: React.FC<ContainerProps> = (props) => {
	return (
		<>
			<AdminTopNavigationProps />
			<div className='h-full bg-gray-50 font-montserrat pt-6 px-3 sm:px-8 lg:px-12 overflow-hidden'>
				{props.children}
			</div>
		</>
	);
};

export default AdminBodyContentContainer;
