import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavigationItem } from "../types/layouts/navbar-type";
import { useNavigate, useLocation } from "react-router";
import { ReactComponent as Logo } from "../assets/bayrun_wtf_logo.svg";
import MenuItem from "./top-menu-item";
import { useApiCall } from "../hooks/api-call";
import { useAuth } from "../providers/auth-provider";

const navigation: NavigationItem[] = [
	{ name: "Dashboard", url: "/admin/dashboard" },
	{ name: "All Runners", url: "/admin/runners" },
	{ name: "eHistory", url: "/admin/email-history" },
	{ name: "Logo", url: "/admin/dashboard" },
	{ name: "Email", url: "/admin/email-browser" },
	{ name: "Settings", url: "/admin/settings" },
	{ name: "Logout", url: "/#" },
];

const navigationMobile: NavigationItem[] = [
	{ name: "Logo", url: "/admin/dashboard" },
	{ name: "Dashboard", url: "/admin/dashboard" },
	{ name: "All Runners", url: "/admin/runners" },
	{ name: "Email", url: "/admin/email-browser" },
	{ name: "eHistory", url: "/admin/email-history" },
	{ name: "Settings", url: "/admin/settings" },
];

export const AdminTopNavigationProps: React.FC = () => {
	const { onLogout } = useAuth();
	const { execute, isLoading } = useApiCall(onLogout!);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { pathname: location } = useLocation();
	const [isScrolled, setIsScrolled] = useState(false);
	const navigate = useNavigate();

	const handleLogout = async () => {
		await execute();
		navigate("/");
	};

	const handleScroll = () => {
		// Set state based on scroll position
		const scrolled = window.scrollY > 50;
		setIsScrolled(scrolled);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<header className='tracking-widest font-montserrat justify-center sticky inset-x-0 top-0 z-50 bg-cover bg-no-repeat bg-center'>
			<nav
				className={`h-[120px] flex flex-col justify-center px-6 py-5 lg:px-8 bg-[#13294B] text-white font-extralight ${
					location !== "/" || isScrolled ? "bg-[#13294B]" : "lg:bg-transparent"
				}`}
				aria-label='Global'>
				<div className='flex lg:flex-1'>
					<div className='-m-1.5 ml-8 p-1.5'>
						<span className='sr-only'>BayRunWTF</span>
					</div>
				</div>
				<div className='flex justify-between lg:hidden'>
					<button
						type='button'
						className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white'
						onClick={() => setMobileMenuOpen(true)}>
						<span className='sr-only'>Open main menu</span>
						<Bars3Icon className='h-8 w-8' aria-hidden='true' />
					</button>
				</div>
				{/* Menu Items! */}
				<div className='hidden lg:flex lg:gap-x-6 lg:mr-10 lg:justify-between items-center'>
					{navigation.map((item, index) =>
						item.name !== "Logout" ? (
							<MenuItem
								key={item.name}
								item={item}
								isActive={location === item.url}
								isScrolled={isScrolled}
							/>
						) : (
							<div
								key={index}
								onClick={handleLogout}
								className='text-2xl font-normal leading-6 relative px-1 inline-block min-w-[60px] text-center border-b border-transparent mr-1 cursor-pointer'>
								Log out
							</div>
						)
					)}
				</div>
			</nav>
			<Dialog
				as='div'
				className='lg:hidden tracking-widest '
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}>
				<div className='fixed inset-0 z-50' />
				<Dialog.Panel
					className={`text-sm font-light fixed inset-y-0 left-0 z-50 w-1/2 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10`}>
					<div className='flex items-center justify-between'>
						<Logo
							onClick={() => {
								navigate("/admin/dashboard");
								setMobileMenuOpen(false);
							}}
							className='cursor-pointer h-28 py-2'
						/>

						<button
							type='button'
							className='-m-2.5 rounded-md p-2.5 text-gray-700'
							onClick={() => setMobileMenuOpen(false)}>
							<span className='sr-only'>Close menu</span>
							<XMarkIcon className='h-6 w-6' aria-hidden='true' />
						</button>
					</div>
					<div className='mt-6 flow-root'>
						<div className='-my-6 divide-y divide-gray-500/10'>
							<div className='space-y-2 py-6'>
								{navigationMobile.map(
									(item) =>
										item.name !== "Logo" && (
											<div
												key={item.name}
												onClick={(e) => {
													e.preventDefault();
													navigate(item.url);

													setMobileMenuOpen(false);
												}}
												className='cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base font-light leading-7 text-gray-900 hover:bg-gray-50'>
												{
													<a href='/' className='-m-1.5 p-1.5'>
														{item.name}
													</a>
												}
											</div>
										)
								)}
							</div>
							<div className='py-6'>
								<div
									onClick={handleLogout}
									className='text-sm font-semibold -mx-3 block rounded-lg px-3 py-2.5  leading-7 hover:bg-gray-50 cursor-pointer'>
									Log out
								</div>
							</div>
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</header>
	);
};
