import { store } from "../store/store";
import { updateAccessToken } from "../store/slices/auth-slice";

export const StateTokenService = {
	getAccessToken: async () => {
		return store.getState().auth.accessToken;
	},
	updateAccessToken: async (token: string) => {
		store.dispatch(updateAccessToken(token));
	},
};
