import { BaseApi } from "../base-api";
import axios, { AxiosResponse } from "axios";

// not to be consumed directly by components
export class AuthApi extends BaseApi {
	protected static instance: AuthApi;

	private constructor() {
		super();
	}

	public static getInstance(): AuthApi {
		if (!this.instance) {
			this.instance = new AuthApi();
		}
		return this.instance;
	}

	async login(username: string, password: string): Promise<AxiosResponse> {
		return await axios.post(
			`${this.BASE_URL}auth/login`,
			{ email: username, password: password },
			{
				withCredentials: true,
			}
		);
	}

	async logout(): Promise<AxiosResponse> {
		return await this.authedApi.delete(`${this.BASE_URL}auth/logout`, {
			withCredentials: true,
		});
	}

	async validateAdminLevelAccess(): Promise<boolean> {
		const response = await this.authedApi.get(
			`${this.BASE_URL}auth/admin/validate`,
			{
				withCredentials: true,
			}
		);
		return response.status === 200;
	}
}
