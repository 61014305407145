import { GiChoice } from "react-icons/gi";
import { MapComponent } from "../../components/Map/MapComponent";
import { AnimatedIconItem } from "../Details/module/animation-icon-item";
import { FaHourglassStart } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

export const Summary: React.FC = () => {
	const navigate = useNavigate();
	return (
		<section>
			<section className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-20 items-start justify-items-center lg:justify-center text-center mb-28 mx-1 lg:mx-8'>
				<article className='flex flex-col items-center lg:col-span-3'>
					<header className='flex flex-col justify-center items-center'>
						<h1 className='text-4xl lg:text-5xl font-light tracking-wider mb-4 sm:mb-8 text-[#45868B]'>
							29 March 2024
						</h1>
						<h1 className='text-4xl lg:text-5xl font-light tracking-wider mb-4 text-[#45868B]'>
							08:00AM
						</h1>
					</header>
				</article>
				<article className='flex flex-col items-center'>
					<header className='flex items-center'>
						<AnimatedIconItem
							IconElement={
								<GiChoice
									style={{ color: "#45868B" }}
									className='w-6 h-6 lg:w-8 lg:h-8 mr-5'
								/>
							}
						/>
						<h1 className='text-lg lg:text-4xl font-semibold tracking-wider mb-1 text-[#45868B]'>
							Choose Your Challenge
						</h1>
					</header>
					<p className='text-lg font-extralight text-justify leading-loose'>
						No need to commit to the full 24 laps! Specify the number of laps
						you plan to conquer, setting your own personal race and goal.
					</p>
				</article>
				<article className='flex flex-col items-center'>
					<header className='flex items-center'>
						<AnimatedIconItem
							IconElement={
								<FaHourglassStart
									style={{ color: "#45868B" }}
									className='w-6 h-6 lg:w-8 lg:h-8 mr-5'
								/>
							}
						/>
						<h1 className='text-lg lg:text-4xl font-semibold tracking-wider mb-1 text-[#45868B]'>
							Flexible Start Times
						</h1>
					</header>
					<p className='text-lg font-extralight text-justify leading-loose'>
						Join the event at any hour after the official 8 am start. For those
						not tackling all 24 laps, notify the timekeeper of your arrival and
						intended starting hour.
					</p>
				</article>
				<article className='flex flex-col items-center'>
					<header className='flex items-center'>
						<AnimatedIconItem
							IconElement={
								<CiLocationOn
									style={{ color: "#45868B" }}
									className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
								/>
							}
						/>
						<h1 className='text-lg lg:text-4xl font-semibold tracking-wider mb-1 text-[#45868B]'>
							Race Location & Timing
						</h1>
					</header>
					<p className='text-lg font-extralight text-justify leading-loose'>
						The iconic Inaugural Bayrun WTF starts and finishes at Callan Park.
						Complete one lap of the bay, covering 6.8km, every hour. Start each
						lap on the hour, with the final lap on Sunday at 7 am.
					</p>
				</article>
				<article className='flex flex-col items-center lg:col-span-3'>
					<header className='flex items-center border-2 rounded-2xl p-4 mt-3 hover:bg-[#13294B] hover:bg-opacity-55 hover:cursor-pointer'>
						<button
							className='font-light text-[#45868B] text-lg sm:text-2xl tracking-widest'
							onClick={() => navigate("/details")}>
							More Info
						</button>
					</header>
				</article>
			</section>
			<section>
				<MapComponent height='650px' heightMobile='550px' />
			</section>
		</section>
	);
};
