import { APP_CONSTANTS } from "../utils/constants";

interface InfoApiResponse {
	time: number;
}

export class InfoApi {
	private static instance: InfoApi;
	private readonly BASE_URL: string;

	private constructor() {
		this.BASE_URL = "https://worldtimeapi.org/";
	}

	public static getInstance(): InfoApi {
		if (!this.instance) {
			this.instance = new InfoApi();
		}

		return this.instance;
	}

	private async fetchApi(
		url: string,
		options?: RequestInit
	): Promise<Response> {
		const response = await fetch(`${this.BASE_URL}${url}`, options);

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		return response;
	}

	public async getCurrentTime(): Promise<InfoApiResponse> {
		const response = await this.fetchApi("api/timezone/Australia/Sydney");		
		return response.json() as Promise<InfoApiResponse>;
	}
}
