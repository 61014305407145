import { BaseApi } from "../base-api";
import { AxiosResponse } from "axios";

export class AuthEventsApi extends BaseApi {
	protected static instance: AuthEventsApi;

	private constructor() {
		super();
	}

	public static getInstance(): AuthEventsApi {
		if (!this.instance) {
			this.instance = new AuthEventsApi();
		}

		return this.instance;
	}

	async getEventDetails(eventName: string): Promise<AxiosResponse> {
		const response = await this.authedApi.get(
			`${this.BASE_URL}admin/event/details?name=${eventName}`,
			{ withCredentials: true }
		);

		return response;
	}

	async updateEventRegLimit(
		eventName: string,
		regLimit: number,
		isForceEmail: boolean
	): Promise<AxiosResponse> {
		const response = await this.authedApi.put(
			`${this.BASE_URL}admin/event/details?name=${eventName}`,
			{
				max_registration_number: regLimit,
				is_force_email_confirmation: isForceEmail,
			},
			{
				withCredentials: true,
			}
		);

		return response;
	}
}
