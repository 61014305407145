import { useState } from "react";
import { AxiosResponse } from "axios";

type ApiFunction<T> = (...args: any[]) => Promise<T>;

type UseApiCallReturnType<T> = {
	execute: (...args: any[]) => Promise<T | null>;
	isLoading: boolean;
	errorMessage: string;
	onError: (callback: () => void) => void;
};

export const useApiCall = <T>(
	apiFunc: ApiFunction<T>
): UseApiCallReturnType<T> => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [onErrorCallback, setOnErrorCallback] = useState<(() => void) | null>(
		null
	);

	const execute = async (...args: any[]): Promise<T | null> => {
		setIsLoading(true);
		setErrorMessage("");
		try {
			return await apiFunc(...args);
		} catch (error: any) {
			setErrorMessage(error.message);
			if (error.response) {
				const axiosError = error.response as AxiosResponse;
				console.error("API Error:", axiosError.status, axiosError.data);
			} else {
				console.error("API Error:", error.message);
			}
			if (onErrorCallback) onErrorCallback();
			return null;
		} finally {
			setIsLoading(false);
		}
	};

	const onError = (callback: () => void) => {
		setOnErrorCallback(() => callback);
	};

	return { execute, isLoading, errorMessage, onError };
};
