import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavigationItem } from "../types/layouts/navbar-type";
import { useNavigate, useLocation } from "react-router";
import { ReactComponent as Logo } from "../assets/bayrun_wtf_logo.svg";
import MenuItem from "./top-menu-item";

const navigation: NavigationItem[] = [
	{ name: "BayRunWTF", url: "/" },
	{ name: "Logo", url: "/" },
	{
		name: "Information",
		url: "#",
		children: [
			{ name: "Summary", url: "/#ABOUT" },
			{ name: "Details", url: "/details" },
			{ name: "Runners", url: "/runners" },
		],
	},
	// { name: "Register", url: "/register" },
	// { name: "login", url: "/login" },
];

const navigationMobile: NavigationItem[] = [
	{ name: "BayRunWTF", url: "/" },
	{ name: "Summary", url: "/#ABOUT" },
	{ name: "Details", url: "/details" },
	// { name: "Runners", url: "/runners" },
	{ name: "Logo", url: "/" },
	// { name: "Register", url: "/register" },
];

interface PublicTopNavigationProps {
	handleSummaryToggle: () => void;
}

export const PublicTopNavigation: React.FC<PublicTopNavigationProps> = (
	props
) => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { pathname: location } = useLocation();
	const [isScrolled, setIsScrolled] = useState(false);
	const navigate = useNavigate();
	const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleScroll = () => {
		// Set state based on scroll position
		const scrolled = window.scrollY > 50;
		setIsScrolled(scrolled);
	};

	const handleSummaryClick = () => {
		// Directly navigate to About section and handle toggle
		navigate("/#ABOUT");
		props.handleSummaryToggle();
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<header className='tracking-widest font-montserrat justify-center sticky inset-x-0 top-0 z-50 bg-cover bg-no-repeat bg-center'>
			<nav
				className={`h-[120px] flex flex-col justify-center px-6 py-5 lg:px-8 bg-[#13294B] text-white font-extralight ${
					location !== "/" || isScrolled ? "bg-[#13294B]" : "lg:bg-transparent"
				}`}
				aria-label='Global'>
				<div className='flex lg:flex-1' onClick={() => navigate("/")}>
					<a className='-m-1.5 ml-8 p-1.5'>
						<span onClick={() => navigate("/")} className='sr-only'>
							BayRunWTF
						</span>
					</a>
				</div>
				<div className='flex justify-between lg:hidden'>
					<button
						type='button'
						className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white'
						onClick={() => setMobileMenuOpen(true)}>
						<span className='sr-only'>Open main menu</span>
						<Bars3Icon className='h-8 w-8' aria-hidden='true' />
					</button>
				</div>
				{/* Menu Items! */}
				<div className='hidden lg:flex lg:gap-x-6 lg:mr-10 lg:justify-between items-center'>
					{navigation.map((item) =>
						item.children ? (
							<MenuItem
								key={item.name}
								item={item}
								isActive={location === item.url}
								handleSummaryToggle={props.handleSummaryToggle}
								isScrolled={isScrolled}
								dropdownOpen={dropdownOpen}
								toggleDropdown={toggleDropdown}>
								{/* Dropdown Items */}
								{item.children.map((childItem) => (
									<a
										key={childItem.name}
										className='text-lg leading-6 block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer'
										onClick={() => {
											setMobileMenuOpen(false);

											childItem.name === "Summary" &&
												props.handleSummaryToggle();

											navigate(childItem.url);
										}}>
										{childItem.name}
									</a>
								))}
							</MenuItem>
						) : (
							<MenuItem
								key={item.name}
								item={item}
								isActive={location === item.url}
								isScrolled={isScrolled}
							/>
						)
					)}
				</div>
			</nav>
			<Dialog
				as='div'
				className='lg:hidden tracking-widest '
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}>
				<div className='fixed inset-0 z-50' />
				<Dialog.Panel
					className={`text-sm font-light fixed inset-y-0 left-0 z-50 w-1/2 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10`}>
					<div className='flex items-center justify-between'>
						<Logo
							onClick={() => {
								navigate("/");
								setMobileMenuOpen(false);
							}}
							className='cursor-pointer h-28 py-2'
						/>
						<button
							type='button'
							className='-m-2.5 rounded-md p-2.5 text-gray-700'
							onClick={() => setMobileMenuOpen(false)}>
							<span className='sr-only'>Close menu</span>
							<XMarkIcon className='h-6 w-6' aria-hidden='true' />
						</button>
					</div>
					<div className='mt-6 flow-root'>
						<div className='-my-6 divide-y divide-gray-500/10'>
							<div className='space-y-2 py-6'>
								{navigationMobile.map(
									(item) =>
										item.name !== "Logo" &&
										item.name !== "Login" && (
											<div
												key={item.name}
												onClick={(e) => {
													if (item.name === "Summary") {
														e.preventDefault();
														handleSummaryClick();
													} else {
														e.preventDefault();
														navigate(item.url);
													}
													setMobileMenuOpen(false);
												}}
												className='cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base font-light leading-7 text-gray-900 hover:bg-gray-50'>
												{item.name === "About" ? (
													"About"
												) : (
													<div
														onClick={() => navigate("/")}
														className='-m-1.5 p-1.5'>
														{item.name}
													</div>
												)}
											</div>
										)
								)}
							</div>
							<div className='py-6'>
								{/* <div
									onClick={() => {
										setMobileMenuOpen(false);
										navigate("/login");
									}}
									className='text-sm font-semibold -mx-3 block rounded-lg px-3 py-2.5  leading-7 hover:bg-gray-50 cursor-pointer'>
									Log in
								</div> */}
							</div>
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</header>
	);
};
