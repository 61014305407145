import React from "react";
import { Summary } from "../Summary/Summary";
import { VideoBackground } from "./modules/video-background";
import BodyContentContainer from "../../layouts/public-body-content-container";
import { ScrollToTopButton } from "../../components/ScrollButton/ScrollButton";

interface HomeProps {
	aboutRef: React.RefObject<HTMLDivElement>;
}

export const Home: React.FC<HomeProps> = (props) => {
	return (
		<main className='flex flex-col lg:w-full'>
			<section className='h-[90vh] w-full'>
				<VideoBackground />
			</section>
			<article ref={props.aboutRef}>
				<Summary />
			</article>
			<ScrollToTopButton />
		</main>
	);
};
