import React from "react";
import { NavigationItem } from "../types/layouts/navbar-type";
import { ReactComponent as Logo } from "../assets/bayrun_wtf_logo.svg";
import { useNavigate } from "react-router-dom";

interface MenuItemProps {
	item: NavigationItem;
	isActive: boolean;
	handleSummaryToggle?: () => void;
	isScrolled: boolean;
	dropdownOpen?: boolean;
	toggleDropdown?: () => void;
	children?: React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
	const navigate = useNavigate();
	if (props.item.name === "Logo") {
		// Render the logo
		return (
			<Logo
				onClick={() => {
					navigate(props.item.url);
				}}
				className='cursor-pointer h-28 py-2'
			/>
		);
	} else if (props.item.name === "Information") {
		// Render the dropdown menu for 'Information'
		return (
			<div className='relative'>
				<button
					onClick={props.toggleDropdown}
					className='underline underline-offset-8 text-2xl font-normal leading-6'>
					{props.item.name}
				</button>
				{/* Conditional rendering of the dropdown */}
				{props.dropdownOpen && (
					<div
						onClick={props.toggleDropdown}
						className='absolute left-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20'>
						{props.children}
					</div>
				)}
			</div>
		);
	} else {
		return (
			<div
				onClick={() => navigate(props.item.url)}
				className={`text-2xl font-normal leading-6 relative px-1 inline-block min-w-[60px] text-center border-b border-transparent mr-1 cursor-pointer`}>
				{props.item.name}
			</div>
		);
	}
};

export default MenuItem;
