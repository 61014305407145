import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { DateTimeCalculations } from "../../utils/date-time-calculations";
import { Formik, Form } from "formik";
import ModalSingleButton from "./modal-single-button";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { SuccessModalText } from "../../public-pages/RegisterInterest/modules/success-modal-text";
import Loading from "../overlays/Loading";
import TextInputCombo from "../../layouts/ui/Text-input-combo";
import { RunnerDTO } from "../../types/runner-types";
import { AuthRunnersApi } from "../../api/auth/runners-details-api";

interface RunnerEditModalProps extends RunnerDTO {
	handleClose: () => void;
}

export const RunnerEditModal: React.FC<RunnerEditModalProps> = (props) => {
	const formikRef = useRef(null);
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const INITIAL_FORM_STATE = {
		id: props.id,
		first_name: props.first_name,
		last_name: props.last_name,
		target_lap_count: props.target_lap_count,
		intended_start_time: props.intended_start_time
			.split(":")
			.slice(0, 2)
			.join(":"),
		email: props.email,
	};

	const FORM_VALIDATION = yup.object().shape({
		first_name: yup.string().required("Required"),
		last_name: yup.string().required("Required"),
		target_lap_count: yup.number().moreThan(0).required("Required"),
		intended_start_time: yup
			.string()
			.required("Required")
			.test(
				"is-time-in-range",
				"Time must be between 08:00AM and 00:00AM 19th May",
				DateTimeCalculations.isValidTimeRange
			),
		email: yup.string().email("Invalid email address").required("Required"),
	});

	return (
		<main className='m-1'>
			{errorMessage && (
				<ModalSingleButton
					title={"Something went wrong.."}
					icon={
						<ExclamationTriangleIcon
							className='h-6 w-6 text-red-600'
							aria-hidden='true'
						/>
					}
					message={errorMessage}
					onClose={() => {
						setErrorMessage("");
						navigate("/admin/runners");
					}}
				/>
			)}
			{successMessage && (
				<section className='p-5'>
					<SuccessModalText message={successMessage} />
				</section>
			)}
			{isLoading && <Loading />}
			{!errorMessage && !successMessage && (
				<Formik
					innerRef={formikRef}
					initialValues={INITIAL_FORM_STATE}
					validationSchema={FORM_VALIDATION}
					onSubmit={async (values) => {
						try {
							setIsLoading(true);
							const response: any =
								await AuthRunnersApi.getInstance().editRunnerDetails(values);
							const mockTimer = setTimeout(() => {
								setIsLoading(false);
								setSuccessMessage(response.data.message);
							}, 400);
						} catch (error) {
							console.log(`Registration error: ${error}`);
							setIsLoading(false);

							setErrorMessage(
								`Something went wrong while sending your Registration. \n ${error}`
							);
						}
					}}>
					{(formikProps) => (
						<Form className='font-montserrat'>
							<div className='grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 mt-1 sm:mb-10'>
								<TextInputCombo
									type='text'
									label='id'
									name='id'
									value={INITIAL_FORM_STATE.id}
									containerClass='hidden'
									onChange={formikProps.handleChange}
								/>
								<div className='flex gap-5'>
									<TextInputCombo
										type='text'
										label='First Name'
										placeHolder={props.first_name}
										name='first_name'
										value={INITIAL_FORM_STATE.first_name}
										containerClass='col-span-1 lg:col-span-2 xl:col-span-3'
										onChange={formikProps.handleChange}
									/>
									<TextInputCombo
										type='text'
										label='Last Name'
										name='last_name'
										value={INITIAL_FORM_STATE.last_name}
										containerClass='col-span-1 lg:col-span-2 xl:col-span-3'
										onChange={formikProps.handleChange}
									/>
								</div>
								<TextInputCombo
									type='email'
									label='Email'
									name='email'
									value={INITIAL_FORM_STATE.email}
									containerClass='col-span-1 lg:col-span-2 xl:col-span-3'
									onChange={formikProps.handleChange}
								/>
								<div className='flex justify-between gap-5 mb-5'>
									<TextInputCombo
										type='number'
										inputMode='numeric'
										label='Target Lap Count'
										name='target_lap_count'
										value={INITIAL_FORM_STATE.target_lap_count}
										containerClass='col-span-1 lg:col-span-2 xl:col-span-3'
										onChange={formikProps.handleChange}
									/>
									<TextInputCombo
										type='time'
										label='Start Time'
										name='intended_start_time'
										value={INITIAL_FORM_STATE.intended_start_time}
										containerClass='col-span-1 lg:col-span-2 xl:col-span-3'
										onChange={formikProps.handleChange}
									/>
								</div>
								<div className='col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-6 flex justify-center items-center'>
									<button
										type='submit'
										className='px-3 py-2 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75 w-full sm:w-auto'>
										Submit
									</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			)}
		</main>
	);
};
