import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, FormControlLabel } from "@mui/material";
import { IRunner } from "../../types/runner-types";

interface AccordionProps {
	runners: IRunner[];
	checked: boolean;
	setRunnerEmailList: (runners: IRunner[]) => void;
	setCheckedState: React.Dispatch<React.SetStateAction<RunnerCheckboxState>>;
	checkedState: RunnerCheckboxState;
}

interface RunnerCheckboxState {
	[key: string]: boolean; // This tells TypeScript that the object can have any number of keys of type string, and their values are boolean
}

export const MyAccordionComponent: React.FC<AccordionProps> = (props) => {
	const handleCheckboxChange = (runnerId: number, isChecked: boolean) => {
		props.setCheckedState((prevCheckedState: any) => ({
			...prevCheckedState,
			[runnerId]: isChecked,
		}));
	};

	return (
		<div className='flex flex-col editor-container w-full'>
			{props.checked ? (
				<Accordion disabled>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1-content'
						id='panel1-header'>
						Email List
					</AccordionSummary>
				</Accordion>
			) : (
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1-content'
						id='panel1-header'>
						Email List
					</AccordionSummary>
					<AccordionDetails
						className='grid grid-cols-1 sm:grid-cols-2 sm:gap-3'
						style={{ maxHeight: "300px", overflowY: "auto" }}>
						{props.runners.map((runner) => (
							<div key={runner.id}>
								<FormControlLabel
									label={runner.email}
									control={
										<Checkbox
											className='text-center items-center'
											checked={
												props.checked
													? props.checked
													: props.checkedState[runner.id] ?? false
											}
											onChange={(e) =>
												handleCheckboxChange(runner.id, e.target.checked)
											}
										/>
									}
								/>
							</div>
						))}
					</AccordionDetails>
				</Accordion>
			)}
		</div>
	);
};
