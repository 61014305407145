import React from "react";
import { useField, useFormikContext } from "formik";
import { FaTimes } from "react-icons/fa";

interface IToggleInputCombo {
	label: string;
	name: string;
	containerClass?: string;
	onText?: string;
	offText?: string;
}

export const ToggleInputCombo: React.FC<IToggleInputCombo> = ({
	label,
	name,
	containerClass,
	onText = "On",
	offText = "Off",
}) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(name);

	const handleToggleClick = () => {
		// Directly update Formik's state
		setFieldValue(name, !field.value);
	};

	return (
		<div
			className={`${containerClass} flex flex-col items-center cursor-pointer`}
			onClick={handleToggleClick}>
			<div className='mb-2 text-sm font-montserrat text-gray-900'>{label}</div>
			<div className='relative'>
				<span className='block w-10 h-6 bg-gray-400 rounded-full shadow-inner'></span>
				<span
					className={`absolute flex items-center justify-center w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out ${
						field.value ? "bg-[#13294B] transform translate-x-full" : "bg-white"
					}`}>
					{!field.value && <FaTimes className='text-red-500' />}
				</span>
			</div>
			<div className='mt-2 text-sm font-montserrat text-gray-400'>
				{field.value ? onText : offText}
			</div>
		</div>
	);
};

export default ToggleInputCombo;
