import { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

export const ScrollToTopButton: React.FC = () => {
	const [isVisible, setIsVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		const toggleVisibility = () => {
			if (window.pageYOffset > 350) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	if (!isVisible) {
		return null;
	}

	return (
		isVisible && (
			<button
				onClick={scrollToTop}
				style={{
					position: "fixed",
					right: "20px",
					bottom: "20px",
					backgroundColor: "#45868B",
					color: "white",
					borderRadius: "50%",
					border: "none",
					padding: "20px",
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<FaArrowUp />
			</button>
		)
	);
};
