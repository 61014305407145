import { createContext, useContext, useEffect, useState } from "react";
import { StateTokenService } from "../services/state-token-service";
import { AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../services/auth-service";

type AuthProps = {
	accessToken: string | null;
	onLogin: (email: string, password: string) => Promise<AxiosResponse>;
	onLogout: () => Promise<any>;
	initialised: boolean;
	setAccessToken: (token: string) => void;
};

const AuthContext = createContext<Partial<AuthProps>>({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
	const [accessToken, setAccessToken] = useState<string | null>(null);
	const [initialised, setInitialised] = useState<boolean>(false);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const loadToken = async () => {
			const storedToken = await StateTokenService.getAccessToken();
			setAccessToken(storedToken);
			if (storedToken) {
				// note: only if path is secured (/user or /admin), do we need to validate token
				if (location.pathname.startsWith("/admin")) {
					try {
						// this validates token but not access level
						const result = await AuthService.validateToken();
					} catch (error) {
						console.log(`Token validation result: error`);
						navigate("/");
						return;
					}

					// the access token could have refreshed during the validate call. let's update context token.
					const latestAccessToken = await StateTokenService.getAccessToken();
					setAccessToken(latestAccessToken);
				}

				if (location.pathname === "/login") {
					try {
						const result = await AuthService.validateAdminLevelAccess();
						if (result) {
							navigate("/admin/dashboard");
						}
					} catch (error) {
						console.log("route is /login, not validated for user level access");
					}
				}
			} else if (!location.pathname.startsWith("/admin")) {
				return;
			} else {
				if (location.pathname === "/login") {
					return;
				}
				console.log("You need to login");
				navigate("/");
			}
		};

		loadToken();
	}, [accessToken, initialised]);

	const handleLogin = async (username: string, password: string) => {
		const result = await AuthService.login(username, password);

		if (result.status !== 200) {
			return result;
		}

		const authResponse = result.data;
		// set context state
		setAccessToken(authResponse.accessToken);

		// set persisted state via redux
		await StateTokenService.updateAccessToken(authResponse.accessToken);

		return result;
	};

	const handleLogout = async (): Promise<AxiosResponse> => {
		// needs token to logout from cognito
		const result = await AuthService.logout();

		// now that we have logged out of cognito, we can remove the token from the provider and state
		setAccessToken(null);
		await StateTokenService.updateAccessToken("");

		return result;
	};

	return (
		<AuthContext.Provider
			value={{
				initialised: initialised,
				onLogin: handleLogin,
				onLogout: handleLogout,
				accessToken: accessToken,
				setAccessToken,
			}}>
			{children}
		</AuthContext.Provider>
	);
};
