import React, { useEffect, useState } from "react";
import { IRunner } from "../types/runner-types";
import { AuthRunnersApi } from "../api/auth/runners-details-api";
import { AuthEmailApi } from "../api/auth/email-api";
import { EmailEditor } from "../components/overlays/EmailEditor";
import { MyAccordionComponent } from "../components/Accordion/Accordion";
import { Checkbox } from "@mui/material";
import { EditorState } from "lexical";
import Loading from "../components/overlays/Loading";
import ModalSingleButton from "../components/modal/modal-single-button";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { SuccessModalText } from "../public-pages/RegisterInterest/modules/success-modal-text";

interface RunnerCheckboxState {
	[key: string]: boolean; // This tells TypeScript that the object can have any number of keys of type string, and their values are boolean
}
enum PageType {
	OPTIONS = 0,
	GENERAL = 1,
	CONFIRM_REGISTER = 2,
}

export const EmailBrowser: React.FC = () => {
	const [runners, setRunners] = useState<IRunner[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [mailSubject, setMailSubject] = useState("");
	const [runnerEmailList, setRunnerEmailList] = useState<IRunner[]>([]);
	const [isSendAllSelected, setIsSendAllSelected] = React.useState(true);
	const [editorState, setEditorState] = useState<EditorState>();
	const [pageType, setPageType] = useState(PageType.OPTIONS);
	const [checkedState, setCheckedState] = React.useState<RunnerCheckboxState>(
		() =>
			runners.reduce((acc: RunnerCheckboxState, currRunner) => {
				acc[currRunner.id] = isSendAllSelected;
				return acc;
			}, {})
	);

	const handleRadioButtonChange = () => {
		setIsSendAllSelected(!isSendAllSelected);
	};

	useEffect(() => {
		const getRunners = async () => {
			try {
				setIsLoading(true);
				const runnersResult =
					await AuthRunnersApi.getInstance().getAllRunnerDetails();
				const runnersList: IRunner[] = runnersResult.data.data;
				setRunners(runnersList);
			} catch (error) {
				console.error("Failed to fetch runners:", error);
				setErrorMessage("Failed to fetch runners");
			} finally {
				setIsLoading(false);
			}
		};
		getRunners();
	}, []);

	const handleSendButton = async () => {
		try {
			setIsLoading(true);
			let emailList = [];

			if (isSendAllSelected) {
				emailList = runners.map((runner) => runner.email);
			} else {
				emailList = runners
					.filter((runner) => checkedState[runner.id])
					.map((runner) => runner.email);
			}

			if (!editorState) {
				console.error("Editor state is undefined");
				return;
			}
			let payload = { emailList, email: editorState, subject: mailSubject };

			if (pageType === 1) {
				const response = await AuthEmailApi.getInstance().sendEmail(payload);
				const mockTimer = setTimeout(() => {
					setIsLoading(false);
					setSuccessMessage(response.data.message);
				}, 400);
			} else if (pageType === 2) {
				const response =
					await AuthEmailApi.getInstance().sendRegistrationConfirmationEmail(
						payload
					);
				const mockTimer = setTimeout(() => {
					setIsLoading(false);
					setSuccessMessage(response.data.message);
				}, 400);
			}
		} catch (error) {
			console.error("Failed to send emails:", error);
			setErrorMessage("We couldn't send your email...");
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubjectChange = (newSubject: string) => {
		setMailSubject(newSubject);
	};

	const handleChange = (editorState: EditorState) => {
		setEditorState(editorState);
	};

	return (
		<main className='flex flex-col items-center'>
			{successMessage && <SuccessModalText message={successMessage} />}
			{isLoading && <Loading />}
			{errorMessage && (
				<ModalSingleButton
					title={"Something went wrong.."}
					icon={
						<ExclamationTriangleIcon
							className='h-6 w-6 text-red-600'
							aria-hidden='true'
						/>
					}
					message={errorMessage}
					onClose={() => {
						setErrorMessage("");
					}}
				/>
			)}
			{pageType === 0 && (
				<div className='h-[100vh] flex flex-col gap-20 items-center justify-center w-[100%] max-w-2xl'>
					<button
						onClick={() => setPageType(1)}
						className='text-xl sm:text-2xl w-full col-start-3 px-3 py-10 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
						GENERAL
					</button>
					<button
						onClick={() => setPageType(2)}
						className='text-xl sm:text-2xl w-full col-start-3 px-3 py-10 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
						CONFIRM REGISTRATION
					</button>
				</div>
			)}
			{pageType === 1 && (
				<>
					<article>
						<h1 className='text-gray-400 text-xl sm:text-2xl my-10'>
							GENERAL EMAIL
						</h1>
					</article>
					<div className=' flex items-center justify-between w-full editor-container'>
						<div className='flex items-center'>
							<Checkbox
								checked={isSendAllSelected}
								onChange={handleRadioButtonChange}
							/>
							<p>Send Everyone</p>
						</div>
						<button
							onClick={() => setPageType(0)}
							className='w-[150px] col-start-3 px-3 py-2 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
							Back
						</button>
					</div>
					<MyAccordionComponent
						runners={runners}
						checked={isSendAllSelected}
						setRunnerEmailList={setRunnerEmailList}
						setCheckedState={setCheckedState}
						checkedState={checkedState}
					/>
					<div className=' flex items-center w-full editor-container'>
						<label className='mr-2'>Subject:</label>
						<input
							type='text'
							onChange={(e) => handleSubjectChange(e.target.value)}
							className='pl-3 text-gray-600 tracking-wide border border-2 w-full rounded-md h-8'></input>
					</div>
					<EmailEditor handleChange={handleChange} />
					<div className='col-span-3 flex items-center w-full editor-container'>
						<button
							onClick={handleSendButton}
							type='submit'
							className='w-full col-start-3 w-full px-3 py-2 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
							Send
						</button>
					</div>
				</>
			)}
			{pageType === 2 && (
				<>
					<article className='my-10 flex flex-col items-center'>
						<h1 className='text-gray-400 text-xl sm:text-2xl mb-3'>
							REGISTRATION CONFIRMATION EMAIL
						</h1>
						<p className='text-gray-400 text-base sm:text-lg'>
							Confirmation link will be added automatically
						</p>
					</article>
					<div className=' flex items-center justify-between w-full editor-container'>
						<div className='flex items-center'>
							<Checkbox
								checked={isSendAllSelected}
								onChange={handleRadioButtonChange}
							/>
							<p>Send Everyone</p>
						</div>
						<button
							onClick={() => setPageType(0)}
							className='w-[150px] col-start-3 px-3 py-2 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
							Back
						</button>
					</div>
					<MyAccordionComponent
						runners={runners}
						checked={isSendAllSelected}
						setRunnerEmailList={setRunnerEmailList}
						setCheckedState={setCheckedState}
						checkedState={checkedState}
					/>
					<div className=' flex items-center w-full editor-container'>
						<label className='mr-2'>Subject:</label>
						<input
							type='text'
							onChange={(e) => handleSubjectChange(e.target.value)}
							className='pl-3 text-gray-600 tracking-wide border border-2 w-full rounded-md h-8'></input>
					</div>
					<EmailEditor handleChange={handleChange} />
					<div className='col-span-3 flex items-center w-full editor-container'>
						<button
							onClick={handleSendButton}
							type='submit'
							className='w-full col-start-3 w-full px-3 py-2 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
							Send
						</button>
					</div>
				</>
			)}
		</main>
	);
};
