import { useEffect, useRef, useState } from "react";
import { AuthRunnersApi } from "../api/auth/runners-details-api";
// Icons
import { TbEdit } from "react-icons/tb";
// Modals
import ModalBase from "../components/modal/modal-base";
import { RunnerEditModal } from "../components/modal/runner-edit-modal";
import { ScrollToTopButton } from "../components/ScrollButton/ScrollButton";

type Runner = {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	email_confirmed: boolean;
	intended_start_time: string;
	target_lap_count: string;
	confirmed_registry?: boolean;
};

export const AdminAllRunnerDetails: React.FC = () => {
	const [runners, setRunners] = useState<Runner[]>([]);
	const [selectedRunner, setSelectedRunner] = useState<Runner | null>(null);
	const [copyRunners, setCopyRunners] = useState<Runner[]>([]); // Store the original list
	const [search, setSearch] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleEdit = (runner: Runner) => {
		setSelectedRunner(runner);
		setIsModalVisible(true);
	};
	const handleModalClose = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		const getRunners = async () => {
			try {
				const runnersResult =
					await AuthRunnersApi.getInstance().getAllRunnerDetails();
				const runnersList = runnersResult.data.data;
				setRunners(runnersList);
				setCopyRunners(runnersList); // Keep a copy of all runners
			} catch (error) {
				console.error("Failed to fetch runners:", error);
			}
		};
		getRunners();
	}, [isModalVisible]);

	useEffect(() => {
		const filteredRunners = copyRunners.filter((runner) => {
			// Combine first name and last name with a space, then convert to lowercase
			const fullName = [runner.first_name, runner.last_name]
				.join(" ")
				.toLowerCase();
			// Convert the search term to lowercase without altering spaces
			const searchTerm = search.toLowerCase();
			// Check if the full name includes the search term
			return fullName.includes(searchTerm);
		});
		setRunners(filteredRunners);
	}, [search, copyRunners]);

	return (
		<main className='flex flex-col items-center mx-1'>
			{isModalVisible && (
				<ModalBase
					onClose={handleModalClose}
					children={
						selectedRunner && (
							<RunnerEditModal
								id={selectedRunner.id}
								email={selectedRunner.email}
								first_name={selectedRunner.first_name}
								last_name={selectedRunner.last_name}
								intended_start_time={selectedRunner.intended_start_time}
								target_lap_count={selectedRunner.target_lap_count}
								handleClose={handleModalClose}
							/>
						)
					}
					open={isModalVisible}
				/>
			)}
			<h1 className='traching-widest font-light text-lg sm:text-xl mb-4 text-center text-gray-500'>
				Search Runners
			</h1>
			<input
				onChange={(e) => setSearch(e.target.value)}
				className='border border-2 border-gray-200 rounded-lg p-3 w-full sm:w-3/5 mb-5'
			/>
			<div className='-mx-2 overflow-x-auto sm:w-full'>
				<div className='min-w-full sm:min-w-0 p-2'>
					<table className='sm:w-full divide-y divide-gray-200 dark:divide-gray-700'>
						<thead>
							<tr>
								<th className='px-1 py-3 text-xs font-medium text-gray-500 uppercase text-center'>
									Name
								</th>
								<th className='px-1 py-3 text-xs font-medium text-gray-500 uppercase text-center'>
									Time
								</th>
								<th className='px-1 py-3 text-xs font-medium text-gray-500 uppercase text-center'>
									Lap
								</th>
							</tr>
						</thead>
						<tbody className='divide-y divide-gray-200 dark:divide-gray-700'>
							{runners.map((runner, index) => (
								<tr key={index}>
									<td className='px-1 py-6 whitespace-nowrap text-sm text-center w-2/5'>
										<article className='flex items-center justify-start sm:grid sm:grid-cols-3'>
											<TbEdit
												onClick={() => handleEdit(runner)}
												className='cursor-pointer sm:col-start-1 mr-5 w-8 h-8 text-gray-400'
											/>
											<div className='flex flex-col items-start sm:col-start-2  ml-5'>
												<p className='font-semibold text-gray-600 pb-1'>
													{runner.first_name} {runner.last_name}
												</p>
												<p className='font-base text-gray-400'>
													{runner.email}
												</p>
												{!runner.email_confirmed && (
													<p className='font-base text-gray-400'>
														not validated
													</p>
												)}
											</div>
										</article>
									</td>
									<td className='px-1 py-6 whitespace-nowrap text-sm text-center'>
										<p className='font-semibold text-gray-600'>
											{runner.intended_start_time
												.split(":")
												.slice(0, 2)
												.join(":")}
										</p>
									</td>
									<td className='px-1 py-6 whitespace-nowrap text-sm text-center'>
										<p className='font-semibold text-gray-600'>
											{runner.target_lap_count}
										</p>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<ScrollToTopButton />
		</main>
	);
};
