import React from "react";

interface MapOverlayProps {
	show: boolean;
}

export const MapOverlay: React.FC<MapOverlayProps> = (props) => {
	if (!props.show) return null;

	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				backgroundColor: "rgba(255, 255, 255, 0.6)",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				zIndex: 1000, // Ensure it's above the map
				fontSize: "1rem",
				color: "black",
			}}>
			<h1 className='text-lg font-semibold'>Use two fingers to move the map</h1>
		</div>
	);
};
