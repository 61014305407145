import React from "react";

interface CountdownCircleProps {
	label: string;
	value: number;
}

export const CountdownCircle: React.FC<CountdownCircleProps> = (props) => (
	<div className='flex flex-col items-center'>
		<div className='flex justify-center items-center border-2 lg:border-4 border-white rounded-full w-16 h-16 sm:w-24 sm:h-24 lg:w-28 lg:h-28'>
			<p className='text-2xl sm:text-3xl lg:text-4xl font-light tracking-widest'>
				{String(props.value).padStart(2, "0")}
			</p>
		</div>
		<p className='text-xs sm:text-base lg:text-lg font-light tracking-wider'>
			{props.label}
		</p>
	</div>
);
