import { APP_CONSTANTS } from "../utils/constants";
import { StateTokenService } from "../services/state-token-service";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export class BaseApi {
	protected static instance: BaseApi;
	protected authedApi: AxiosInstance;
	readonly BASE_URL: string;

	constructor() {
		this.BASE_URL = APP_CONSTANTS.API_URL;
		this.authedApi = axios.create({
			baseURL: this.BASE_URL,
			withCredentials: true,
		});

		this.authedApi.interceptors.request.use(
			async (config) => {
				const accessToken = await StateTokenService.getAccessToken();
				config.headers.Authorization = `Bearer ${accessToken}`;
				return config;
			},
			(error) => Promise.reject(error)
		);

		this.authedApi.interceptors.response.use(
			(response) => response,
			async (error) => {
				const originalConfig = error.config;

				if (originalConfig.url !== "auth/login" && error.response) {
					// Acess Token was expired
					if (
						(error.response.status === 401 || error.response.status === 403) &&
						!originalConfig._retry
					) {
						originalConfig._retry = true;
						try {
							// console.log("about to refresh acess");
							const response = await axios.post(
								`${this.BASE_URL}auth/refresh-token`,
								null,
								{
									withCredentials: true,
									// httpOnly cookie is attached with every request
								}
							);

							const accessToken = response.data.accessToken;

							// update state // TODO: issue with this is that we need to update context access token state also
							await StateTokenService.updateAccessToken(accessToken);

							// Retry the original request with the updated access token
							originalConfig.headers.Authorization = `Bearer ${accessToken}`;
							return axios(originalConfig);
						} catch (error) {
							return Promise.reject(error);
						}
					}
				}

				return Promise.reject(error);
			}
		);
	}
	public static getInstance(): BaseApi {
		if (!this.instance) {
			this.instance = new BaseApi();
		}
		return this.instance;
	}

	async validateToken() {
		const response = await this.authedApi.get(
			`${this.BASE_URL}auth/validate-token`,
			{
				withCredentials: true,
			}
		);
		return response.data;
	}

	public async get<T = any>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<T> {
		try {
			const response = await this.authedApi.get<T>(url, config);
			return response.data;
		} catch (error) {
			console.error(
				`There was an error while performing GET request to ${url}`,
				error
			);
			throw error;
		}
	}

	public async post<T = any>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	): Promise<T> {
		try {
			const response = await this.authedApi.post<T>(url, data, config);
			return response.data;
		} catch (error) {
			console.error(
				`There was an error while performing POST request to ${url}`,
				error
			);
			throw error;
		}
	}

	async protected() {
		const response = await this.authedApi.get("auth/protected-route");
		return response.data;
	}
}
