import React, { useState } from "react";
import "../../themes/lexical-style.css";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { lexicalTheme } from "../../themes/lexical-theme";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { EditorState } from "lexical";
import { AutoLinkNode, LinkNode } from "@lexical/link";
// Custom plugins
import { MyAutoLinkPlugin } from "../../plugins/autoLinkPlugin";
import { MyOnChangePlugin } from "../../plugins/onChangePlugin";
import { ToolbarPlugin } from "../../plugins/toolbarPlugin";

interface EmailEditorProps {
	handleChange: (editorState: EditorState) => void;
}

export const EmailEditor: React.FC<EmailEditorProps> = (props) => {
	function onError(error: Error): void {
		console.error(error);
	}

	const editorConfig = {
		namespace: "MyEditor",
		theme: lexicalTheme,
		onError,
		nodes: [
			HeadingNode,
			ListNode,
			ListItemNode,
			QuoteNode,
			CodeNode,
			CodeHighlightNode,
			TableNode,
			TableCellNode,
			TableRowNode,
			AutoLinkNode,
			LinkNode,
		],
	};

	return (
		<div className='mt-5'>
			<LexicalComposer initialConfig={editorConfig}>
				<div className='editor-container'>
					<ToolbarPlugin />
					<div className='editor-inner'>
						<RichTextPlugin
							contentEditable={
								<ContentEditable className='overflow-y-auto min-h-[30vh] max-h-[50vh] p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500' />
							}
							placeholder={
								<div className='absolute top-3 left-3 color-gray-400 tracking-widest '>
									Type your email...
								</div>
							}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<HistoryPlugin />
						<MyAutoLinkPlugin />
						<LinkPlugin />
						<ListPlugin />
						<MyOnChangePlugin onChange={props.handleChange} />
					</div>
				</div>
			</LexicalComposer>
		</div>
	);
};
