import { Polygon, Popup } from "react-leaflet";

//@ts-ignore
export const CustomMarker = ({ feature, cssRules }) => {
    const { Entity, AREA_SQ_KM, Iwi } = feature.properties;
    // @ts-ignore
    const coordinates = feature.geometry.coordinates[0].map((coord) => [coord[1], coord[0]]); // Swap lat-lng for Leaflet

    return (
        <Polygon positions={coordinates} color={cssRules.color}>
            <Popup>
                <div className="text-body font-paragraph">
                    <p className="mb-2">
                        Circle the BayRun.WTF and outwit the loop of life. Each lap's a silent nod to your stamina—and a cheeky snub to the council. Keep running; it’s just you and the horizon... and a potential bush loo break.
                    </p>
                </div>
            </Popup>
        </Polygon>
    );
};
