import React, { useRef, useState } from "react";
import BodyContentContainer from "../../layouts/public-body-content-container";
import { MapComponent } from "../../components/Map/MapComponent";
import { CiLocationOn } from "react-icons/ci";
import { FaRunning } from "react-icons/fa";
import { GiChoice } from "react-icons/gi";
import { GiArchiveRegister } from "react-icons/gi";
import { FaHourglassStart } from "react-icons/fa";
import { IoIosRepeat } from "react-icons/io";
import { IoSpeedometerOutline } from "react-icons/io5";
import { MdOutlineIncompleteCircle } from "react-icons/md";
import { LuTimerOff } from "react-icons/lu";
import { BiSolidDirections } from "react-icons/bi";
import { MdOutlineLeaderboard } from "react-icons/md";
import { TbAward } from "react-icons/tb";
import { FaShieldAlt } from "react-icons/fa";
import { GiWebSpit } from "react-icons/gi";
import { AnimatedIconItem } from "./module/animation-icon-item";
import { ScrollToTopButton } from "../../components/ScrollButton/ScrollButton";

export const Details: React.FC = () => {
	const mapComponentRef = useRef<HTMLDivElement>(null);
	const [isMapVisible, setIsMapVisible] = useState(false);

	const handleMapVisibilityToggle = () => {
		setIsMapVisible(!isMapVisible);
		if (mapComponentRef.current) {
			const headerHeight = 15; // Height of the header is 150px manually set
			const elementPosition =
				mapComponentRef.current.getBoundingClientRect().top + window.screenY;
			const offsetPosition = elementPosition - headerHeight;

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
		}
	};

	return (
		<>
			<main>
				<section className='grid grid-cols-1 lg:gap-20 lg:grid-cols-2 xl:gap-18 xl:grid-cols-3 2xl:grid-cols-4'>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<FaRunning
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Event Details
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Join us on Saturday, March 29th, 2025, starting at 8 am, for a
							thrilling 24-hour race around The iconic inaugural Bayrun WTF in
							Sydney's inner west.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<CiLocationOn
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Location
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							The starting and finishing point is Callan Park{" "}
							<a
								className='hover:cursor-pointer text-[#45868B] font-semibold'
								onClick={handleMapVisibilityToggle}>
								(refer to the map for location details).
							</a>
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<GiChoice
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Choose Your Challenge
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							No need to commit to the full 24 laps! Upon registration, specify
							the number of laps you plan to conquer, setting your own personal
							race and goal.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<GiArchiveRegister
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Registration Process
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Please{" "}
							<a href='/register' className='text-[#45868B] font-semibold'>
								register your interest
							</a>{" "}
							in running in this event on the website. At the time of your
							registration, please nominate the number of laps you expect to run
							and your expected start time.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<FaHourglassStart
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Flexible Start Times
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							For those not tackling all 24 laps, feel free to join the event at
							any hour after the official 8 am start. If you're starting later,
							notify the timekeeper of your arrival and intended starting hour.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<IoIosRepeat
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Lap Requirements
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Each participant must complete one lap of the bay, covering 6.8km,
							every hour. Laps commence on the hour, every hour, with the final
							lap starting on Sunday, May 19th, at 7 am.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<IoSpeedometerOutline
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Pace and Timing
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Run each lap at your own pace, but ensure you start the next one
							on the hour with the rest of the participants. As a guide, the
							slowest pace required to complete a lap is 8 minutes and 49.412
							seconds per kilometer.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<MdOutlineIncompleteCircle
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Lap Completion Procedure
							</h1>
						</header>

						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Inform the timekeeper of your completed lap for recording, take a
							break, hydrate, and prepare for the next lap, starting on the
							upcoming hour.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<LuTimerOff
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Time Limit
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Failure to complete a lap within the hour marks the end of your
							race.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<BiSolidDirections
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Choose Your Direction
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Opt to run your laps in either direction, adding variety to your
							experience.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<MdOutlineLeaderboard
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Runner Updates and Leaderboard
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Stay updated throughout the event on our website, where completed
							laps are recorded, and the leaderboard is regularly updated.
							Digital awards corresponding to your achievements will be
							showcased on the leaderboard.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<TbAward
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Digital Awards
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							Celebrate your achievements with digital awards based on the
							number of laps completed:
						</p>
						<ul className='font-extralight list-disc ml-5'>
							<li className='my-2'>
								<span className='tracking-widest font-light'>1 lap </span> -
								Cherry Popper (6.8km)
							</li>
							<li className='my-2'>
								<span className='tracking-widest font-light'>3 laps </span>- 1/2
								Marathon (20.4km)
							</li>
							<li className='my-2'>
								<span className='tracking-widest font-light'>6 laps </span>-
								Marathon (40.8km)
							</li>
							<li className='my-2'>
								<span className='tracking-widest font-light'>8 laps </span>-
								Baby Ultra (54.4km)
							</li>
							<li className='my-2'>
								<span className='tracking-widest font-light'>12 laps</span> -
								Half a Job(81.6km)
							</li>
							<li className='my-2'>
								<span className='tracking-widest font-light'>15 laps</span> -
								Ultra (102km)
							</li>
							<li className='my-2'>
								<span className='tracking-widest font-light'>24 laps</span> -
								WTAF (163.2km)
							</li>
						</ul>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<FaShieldAlt
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								Personal Responsibility
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							By participating, you acknowledge your sole responsibility for
							your well-being, safety, and welfare. This event is entirely
							unsupported, requiring participants to arrange their own support
							crew, nutrition, hydration, and creature comforts. It's not an
							official running event—just a gathering of people keen to run in
							circles for a long time.
						</p>
					</article>
					<article className='my-2 sm:my-8'>
						<header className='flex items-center'>
							<AnimatedIconItem
								IconElement={
									<GiWebSpit
										style={{ color: "#45868B" }}
										className='w-8 h-8 lg:w-8 lg:h-8 mr-5'
									/>
								}
							/>
							<h1 className='text-base lg:text-3xl font-semibold tracking-wider mb-1 text-[#45868B]'>
								About the Website
							</h1>
						</header>
						<p className='text-base lg:text-lg font-extralight text-justify leading-loose my-4'>
							While the website presence may suggest an official organised
							event, it's crucial to note that this couldn't be further from the
							truth. The development and hosting of this website are the result
							of a hobby project undertaken by a member of the running
							community. The website's existence does not confer any official
							status upon the event. It's just a fun gathering of running
							enthusiasts coming together to enjoy a circular challenge!
						</p>
					</article>
					<div
						ref={mapComponentRef}
						className='my-16 sm:my-8 lg:col-span-2 xl:col-span-3 2xl:col-span-4'>
						<MapComponent height='650px' heightMobile='450px' />
					</div>
					<ScrollToTopButton />
				</section>
			</main>
		</>
	);
};
