import { BaseApi } from "../base-api";
import { AxiosResponse } from "axios";
import { RunnerDTO, IRunner } from "../../types/runner-types";

export class AuthRunnersApi extends BaseApi {
	protected static instance: AuthRunnersApi;

	private constructor() {
		super();
	}

	public static getInstance(): AuthRunnersApi {
		if (!this.instance) {
			this.instance = new AuthRunnersApi();
		}
		return this.instance;
	}

	async getAllRunnerDetails(): Promise<AxiosResponse> {
		const response = await this.authedApi.get(`${this.BASE_URL}admin/runners`, {
			withCredentials: true,
		});
		return response;
	}

	async editRunnerDetails(runnerDetails: RunnerDTO): Promise<AxiosResponse> {
		const response = await this.authedApi.put(
			`${this.BASE_URL}admin/runners?id=${runnerDetails.id}`,
			{
				first_name: runnerDetails.first_name,
				last_name: runnerDetails.last_name,
				email: runnerDetails.email,
				target_lap_count: runnerDetails.target_lap_count,
				intended_start_time: runnerDetails.intended_start_time,
			},
			{
				withCredentials: true,
			}
		);

		return response;
	}

	async validateRunnerEmail(runnerDetails: IRunner): Promise<AxiosResponse> {
		const response = await this.authedApi.put(
			`${this.BASE_URL}admin/runners/validate-email?id=${runnerDetails.id}`,
			{
				first_name: runnerDetails.first_name,
				last_name: runnerDetails.last_name,
				email: runnerDetails.email,
				email_confirmed: runnerDetails.email_confirmed,
				target_lap_count: runnerDetails.target_lap_count,
				intended_start_time: runnerDetails.intended_start_time,
			},
			{
				withCredentials: true,
			}
		);

		return response;
	}
}
