export const DateTimeCalculations = {
	isLast24Hours: (givenTime: string): boolean => {
		const currentTime = new Date(); // Current time
		const givenTimeConverted = new Date(givenTime); // Convert given time to Date object
		const timeDifference = currentTime.getTime() - givenTimeConverted.getTime(); // Difference in milliseconds
		const twentyFourHoursInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

		return timeDifference <= twentyFourHoursInMs;
	},
	isValidTimeRange: (value: string) => {
		// Assuming value is in HH:MM format and we want to include 00:00 as valid
		if (value === "00:00") {
			return true; // Explicitly allow 00:00
		}

		// Convert to a 24-hour time for comparison
		const [hours, minutes] = value.split(":").map(Number);
		const totalMinutes = hours * 60 + minutes;
		const startMinutes = 8 * 60; // 08:00 in total minutes

		// Check if time is between 08:00 and 23:59 or exactly 00:00
		return totalMinutes >= startMinutes || value === "00:00";
	},
};
