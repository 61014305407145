import React from "react";

interface IModalBaseProps {
	message: string;
}

export const SuccessModalText: React.FC<IModalBaseProps> = (props) => {
	return (
		<div className='flex flex-col'>
			<p className='mt-1 text-lg font-semibold leading-8 tracking-tight text-gray-900'>
				{props.message}
			</p>
		</div>
	);
};
