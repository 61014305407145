import React, { useEffect, useState } from "react";
import { IEmailRecord } from "../types/email-types";
import { AuthEmailApi } from "../api/auth/email-api";
import Loading from "../components/overlays/Loading";
import sanitizeHtml from "sanitize-html";

export const EmailHistory: React.FC = () => {
	const [emailHistory, setEmailHistory] = useState<IEmailRecord[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		const getAllEmails = async () => {
			try {
				setIsLoading(true);
				const emailHistoryResult =
					await AuthEmailApi.getInstance().getEmailHistory();
				const emailHistoryList: IEmailRecord[] = emailHistoryResult.data.data;
				setTimeout(() => {
					setEmailHistory(emailHistoryList);
					setIsLoading(false);
				}, 400);
			} catch (error) {
				console.error("Failed to fetch email history:", error);
				setIsLoading(false);
				setErrorMessage("Failed to fetch email history");
			}
		};

		getAllEmails();
	}, []);

	return (
		<div className='max-w-4xl mx-auto p-4'>
			{isLoading && <Loading />}
			<h1 className='text-2xl font-semibold text-gray-800 mb-4'>
				Email History
			</h1>
			{errorMessage && <p className='text-red-500'>{errorMessage}</p>}
			<div className='space-y-6'>
				{emailHistory.map((email, index) => (
					<main key={email.id} className='bg-white shadow rounded-lg p-4'>
						<p className='text-lg font-medium text-gray-900'>
							Subject: {email.subject}
						</p>
						<div
							className='mt-2 p-2 bg-gray-100 rounded text-gray-800'
							dangerouslySetInnerHTML={{
								__html: sanitizeHtml(email.content),
							}}></div>
						<div className='mt-4'>
							<span className='font-medium text-gray-900'>Receivers:</span>
							<ul className='list-disc list-inside'>
								{email.receiver_list.map((receiver, receiverIndex) => (
									<li key={receiverIndex} className='text-gray-700'>
										{receiver}
									</li>
								))}
							</ul>
						</div>
					</main>
				))}
			</div>
		</div>
	);
};
