import React, { ReactNode } from "react";
import { X } from "react-feather";

interface ModalProps {
	onClose: () => void;
	open: boolean;
	children: ReactNode;
	className?: string;
}

const ModalBase: React.FC<ModalProps> = (props) => {
	return (
		// backdrop
		<div
			onClick={props.onClose}
			className={`
      fixed inset-0 flex justify-center items-center
      transition-colors z-50
      ${props.open ? "visible bg-black/20" : "invisible"}
    `}>
			{/* modal */}
			<div
				onClick={(e) => e.stopPropagation()}
				className={`
        bg-white rounded-xl shadow p-6 transition-all
        ${props.open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        ${props.className ? props.className : ""}
      `}>
				<button
					onClick={props.onClose}
					className={
						"absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
					}>
					<X />
				</button>
				{props.children}
			</div>
		</div>
	);
};

export default ModalBase;
