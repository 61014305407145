import { APP_CONSTANTS } from "../utils/constants";

interface IRunnerRecord {
	id: number;
	first_name: string;
	last_name: string;
	target_lap_count: number;
	email: string;
	intended_start_time: string;
	email_confirmed: boolean;
	agreed_to_terms: boolean;
	created_at: string;
	updated_at: string;
	qr_code_identifier: string;
	confirmed_registry: boolean;
}

export class RunnersApi {
	private static instance: RunnersApi;
	private readonly BASE_URL: string;

	private constructor() {
		this.BASE_URL = APP_CONSTANTS.API_URL as string;
	}

	public static getInstance(): RunnersApi {
		if (!this.instance) {
			this.instance = new RunnersApi();
		}

		return this.instance;
	}

	public async postRunner(runnerData: IRunnerRecord): Promise<void> {
		const response = await fetch(`${this.BASE_URL}runners`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(runnerData),
		})
			.then((res) => res.json())
			.catch((error) => {
				console.error(error);
				throw new Error(`Error creating a new runner: ${error}`);
			});
		if (response.error) {
			console.error(response.error);
			throw new Error(`${response.error}`);
		} else {
			const newRunner: IRunnerRecord = response;
			return newRunner as any;
		}
	}

	public async editRunnerRegistryManually(
		runnerData: IRunnerRecord,
		token: string
	): Promise<void> {
		const response = await fetch(`${this.BASE_URL}runners/edit/registry`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				data: runnerData,
				token: token,
			}),
		}).then((res) => res.json());

		if (response.error) {
			console.error(response.error);
			throw new Error(`${response.error}`);
		} else {
			return response;
		}
	}

	public async getRunnerByEmail(
		email: string,
		token: string
	): Promise<IRunnerRecord> {
		const response = await fetch(
			`${this.BASE_URL}runners/details?email=${email}&token=${token}`,
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		).then((res) => res.json());

		if (response.error) {
			console.error(response.error);
			throw new Error(`${response.error}`);
		} else {
			const newRunner: IRunnerRecord = response;
			return newRunner as any;
		}
	}

	public async getAllRunners(): Promise<IRunnerRecord[]> {
		const response = await fetch(`${this.BASE_URL}runners`, {
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.catch((error) => {
				console.error(error);
				throw new Error(`Failes to fetch runners: ${error}`);
			});

		if (response.error) {
			console.error(response.error);
			throw new Error(`Failes to fetch runners: ${response.error}`);
		} else {
			const runners: IRunnerRecord[] = response;
			return runners;
		}
	}

	public async isRegAvailable(): Promise<any> {
		const response = await fetch(`${this.BASE_URL}runners/is-reg-available`, {
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.catch((error) => {
				console.error(error);
				throw new Error(`Fales to fetch isRegAvailable`);
			});

		return response;
	}
}
