import { useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// Pages
import { Home } from "./public-pages/Home/Home";
import { Leaderboard } from "./public-pages/Runners/Runners";
import { RegisterInterest } from "./public-pages/RegisterInterest/RegisterIneterest";
import { EditRegisterInterest } from "./public-pages/RegisterInterest/EditRegistrationInterest";
import { Details } from "./public-pages/Details/Details";
import { Login } from "./admin-pages/Login/Login";
// Admin Pages
import { Dashboard } from "./admin-pages/Dashboard";
import { AdminProtectedRoute } from "./admin-pages/Admin-protected-route";
import { AdminAllRunnerDetails } from "./admin-pages/Admin-all-runner-details";
import { AdminSettings } from "./admin-pages/AdminSettings";
import { EmailBrowser } from "./admin-pages/Email-browser";
import { EmailHistory } from "./admin-pages/Email-history";
// Redux
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "./providers/auth-provider";
import PublicBodyContentContainer from "./layouts/public-body-content-container";
import AdminBodyContentContainer from "./layouts/admin-body-content-container";

function App() {
	const aboutRef = useRef<HTMLDivElement>(null); // Create a ref here
	const [isAboutVisible, setIsAboutVisible] = useState(false);

	const handleSummaryToggle = () => {
		setIsAboutVisible(!isAboutVisible);
		if (aboutRef.current) {
			const headerHeight = 150; // Height of the header is 150px manually set
			const elementPosition =
				aboutRef.current.getBoundingClientRect().top + window.scrollY;
			const offsetPosition = elementPosition - headerHeight;

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
		}
	};

	return (
		<BrowserRouter>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<AuthProvider>
						<Routes>
							<Route
								path='/'
								element={
									<PublicBodyContentContainer
										children={<Home aboutRef={aboutRef} />}
										handleSummaryToggle={handleSummaryToggle}
									/>
								}
							/>
							<Route
								path='/login'
								element={
									<PublicBodyContentContainer
										children={<Login />}
										handleSummaryToggle={handleSummaryToggle}
									/>
								}
							/>
							<Route
								path='/runners'
								element={
									<PublicBodyContentContainer
										children={<Leaderboard />}
										handleSummaryToggle={handleSummaryToggle}
									/>
								}
							/>
							<Route
								path='/register'
								element={
									<PublicBodyContentContainer
										children={<RegisterInterest />}
										handleSummaryToggle={handleSummaryToggle}
									/>
								}
							/>
							<Route
								path='/edit/register'
								element={
									<PublicBodyContentContainer
										children={<EditRegisterInterest />}
										handleSummaryToggle={handleSummaryToggle}
									/>
								}
							/>
							<Route
								path='/details'
								element={
									<PublicBodyContentContainer
										children={<Details />}
										handleSummaryToggle={handleSummaryToggle}
									/>
								}
							/>
							<Route
								path='/details'
								element={
									<PublicBodyContentContainer
										children={<Login />}
										handleSummaryToggle={handleSummaryToggle}
									/>
								}
							/>
							<Route path='/login' element={<Login />} />
							{/* Admin routes */}
							<Route path='/admin' element={<AdminProtectedRoute />}>
								<Route
									path='settings'
									element={
										<AdminBodyContentContainer children={<AdminSettings />} />
									}
								/>
								<Route
									path='dashboard'
									element={
										<AdminBodyContentContainer children={<Dashboard />} />
									}
								/>
								<Route
									path='email-browser'
									element={
										<AdminBodyContentContainer children={<EmailBrowser />} />
									}
								/>
								<Route
									path='email-history'
									element={
										<AdminBodyContentContainer children={<EmailHistory />} />
									}
								/>
								<Route
									path='runners'
									element={
										<AdminBodyContentContainer
											children={<AdminAllRunnerDetails />}
										/>
									}
								/>
							</Route>
						</Routes>
					</AuthProvider>
				</PersistGate>
			</Provider>
		</BrowserRouter>
	);
}
export default App;
