import React from "react";
import { useInView } from "react-intersection-observer";

interface AnimatedIconItemProp {
	IconElement: any;
}

export const AnimatedIconItem: React.FC<AnimatedIconItemProp> = (props) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<section ref={ref} className={`${inView ? "animate-bounce-once" : ""}`}>
			{props.IconElement}
		</section>
	);
};
