import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { UpdateMapZoomProps } from "./map-types";

export const UpdateMapZoom: React.FC<UpdateMapZoomProps> = ({ zoom }) => {
    const map = useMap();

    useEffect(() => {
        map.setZoom(zoom);
    }, [zoom, map]);

    return null;
};
