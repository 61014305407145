import React, { useEffect, useState, useRef } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import geoJson from "../../assets/tracks.json";
import StartIcon from "../../assets/start.png";
import FinishIcon from "../../assets/finish.png";
import SupportIcon from "../../assets/support.png";
import "leaflet/dist/leaflet.css";
import { UpdateMapZoom } from "./update-map-zoom";
import { CustomMarker } from "./custom-map-marker";
import L from "leaflet";
import { MapOverlay } from "../overlays/MapOverlay";

const customStartIcon = new L.Icon({
	iconUrl: StartIcon,
	iconSize: [40, 40],
	iconAnchor: [15, 30],
	popupAnchor: [0, -30],
});

const customFinishIcon = new L.Icon({
	iconUrl: FinishIcon,
	iconSize: [40, 40],
	iconAnchor: [15, 30],
	popupAnchor: [0, -30],
});

const customSupportIcon = new L.Icon({
	iconUrl: SupportIcon,
	iconSize: [40, 40],
	iconAnchor: [15, 30],
	popupAnchor: [0, -30],
});

interface MapComponentProps {
	height: string;
	heightMobile: string;
}

export const MapComponent: React.FC<MapComponentProps> = (props) => {
	const [showOverlay, setShowOverlay] = useState(false);
	const [zoom, setZoom] = useState(7);
	const [isDraggable, setIsDraggable] = useState(window.innerWidth > 640);
	const mapRef = useRef(null); // Create a ref

	useEffect(() => {
		const handleResize = () => {
			setIsDraggable(window.innerWidth > 640);
			const newZoom = window.innerWidth < 640 ? 14 : 15;
			setZoom(newZoom);
		};
		if (mapRef.current) {
			//@ts-ignore
			const map = mapRef.current.leafletElement;
		}

		window.addEventListener("resize", handleResize);
		handleResize(); // Set initial state

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const handleTouchStart = () => {
		if (window.innerWidth <= 640) {
			setShowOverlay(true);
			const timeOut = setTimeout(() => {
				setShowOverlay(false);
			}, 1000);
		}
	};

	return (
		// leaflet styles are written with inline styles
		<div
			// className={`h-[${props.heightMobile}] sm:h-[${props.height}]`}
			className='h-[450px] sm:h-[650px]'
			style={{
				width: "100%",
				position: "relative",
				backgroundColor: "white",
				zIndex: "0",
			}}
			onTouchStart={handleTouchStart}>
			<MapOverlay show={showOverlay} />
			{
				<MapContainer
					center={[-33.863731, 151.155445]}
					zoom={zoom}
					scrollWheelZoom={false}
					whenReady={() => {
						const map = mapRef.current;
						// Now you have access to the map instance
						// Perform actions on the map instance
					}}
					dragging={isDraggable}
					style={{ height: "100%", width: "100%", backgroundColor: "white" }}>
					<UpdateMapZoom zoom={zoom} />
					<TileLayer
						attribution='&copy; <a href="https://carto.com/attributions">CARTO</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
						url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
					/>
					{geoJson.features.map((feature, index) => (
						<CustomMarker
							key={index}
							feature={feature}
							cssRules={{ color: "blue", weight: 2 }}
						/>
					))}
					<Marker position={[-33.864002, 151.15934]} icon={customStartIcon}>
						<Popup>Start Line.</Popup>
					</Marker>
					<Marker position={[-33.864553, 151.159724]} icon={customFinishIcon}>
						<Popup>Finish Line.</Popup>
					</Marker>
					<Marker position={[-33.863, 151.159392]} icon={customSupportIcon}>
						<Popup>Support Area.</Popup>
					</Marker>
				</MapContainer>
			}
		</div>
	);
};
