import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Formik, Form, FormikProps } from "formik";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { SuccessModalText } from "./modules/success-modal-text";
import Loading from "../../components/overlays/Loading";
import ModalSingleButton from "../../components/modal/modal-single-button";
import ModalBase from "../../components/modal/modal-base";
import TextInputCombo from "../../layouts/ui/Text-input-combo";
import FormCheckboxCombo from "../../layouts/ui/Form-checkbox-combo";
import { RunnersApi } from "../../api/runners-api";
import { DateTimeCalculations } from "../../utils/date-time-calculations";
import { useSearchParams } from "react-router-dom";

interface FormValues {
	first_name: string;
	last_name: string;
	target_lap_count: number;
	intended_start_time: string;
	email: string;
	agreed_to_terms: boolean;
}

const INITIAL_EOI_FORM_STATE = {
	first_name: "",
	last_name: "",
	target_lap_count: 1,
	intended_start_time: "08:00",
	email: "",
	agreed_to_terms: false,
};

const FORM_VALIDATION = yup.object().shape({
	first_name: yup.string().required("Required"),
	last_name: yup.string().required("Required"),
	target_lap_count: yup
		.number()
		.moreThan(0)
		.lessThan(25, "Must be less than 25")
		.required("Required"),
	intended_start_time: yup
		.string()
		.required("Required")
		.test(
			"is-time-in-range",
			"Time must be between 08:00AM and 00:00AM 19th May",
			DateTimeCalculations.isValidTimeRange
		),
	email: yup.string().email("Invalid email address").required("Required"),
	agreed_to_terms: yup.boolean(),
});

export const EditRegisterInterest: React.FC = () => {
	const navigate = useNavigate();
	const formikRef = useRef<FormikProps<FormValues>>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [checked, setChekced] = useState(true);
	const [runnerInfo, setRunnerInfo] = useState({
		first_name: "",
		last_name: "",
		target_lap_time: "",
		qr_identifier: "",
		intended_start_time: "",
		agreed_to_terms: "",
	});
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const getRunnerDetails = async () => {
			const email = searchParams.get("email");
			const token = searchParams.get("token");
			// Only proceed if both email and token are not null
			if (email && token) {
				try {
					setIsLoading(true);
					const runnerInfo = await RunnersApi.getInstance().getRunnerByEmail(
						email,
						token
					);
					// Map the runnerInfo to the expected structure
					const mappedRunnerInfo = {
						id: runnerInfo.id,
						first_name: runnerInfo.first_name,
						last_name: runnerInfo.last_name,
						target_lap_time: runnerInfo.target_lap_count.toString(),
						qr_identifier: runnerInfo.qr_code_identifier,
						intended_start_time: "",
						agreed_to_terms: runnerInfo.agreed_to_terms.toString(),
					};
					setRunnerInfo(mappedRunnerInfo);
					if (formikRef.current) {
						formikRef.current.setValues({
							first_name: runnerInfo.first_name,
							last_name: runnerInfo.last_name,
							target_lap_count: runnerInfo.target_lap_count,
							email: runnerInfo.email,
							agreed_to_terms: runnerInfo.agreed_to_terms,
							intended_start_time: runnerInfo.intended_start_time,
						});
					}
					setIsLoading(false);
				} catch (error) {
					console.error("Error fetching runner info:", error);
					setErrorMessage("Failed to fetch runner information.");
					setIsLoading(false);
				}
			} else {
				setErrorMessage("Email or token is missing.");
			}
		};

		getRunnerDetails();
	}, [searchParams]);

	const closeModal = () => {
		setSuccessMessage("");
		navigate("/runners");
	};

	return (
		<>
			<main className='sm:mt-20'>
				<h1 className='text-lg sm:text-2xl text-center mb-8 sm:mb-10 font-semibold tracking-widest'>
					Register Interest
				</h1>
				<Formik
					innerRef={formikRef}
					initialValues={INITIAL_EOI_FORM_STATE}
					validationSchema={FORM_VALIDATION}
					onSubmit={async (values, { resetForm }) => {
						if (values.agreed_to_terms !== true) {
							setErrorMessage("You must accept the waiver");
							return;
						}
						try {
							setIsLoading(true);
							const token = await searchParams.get("token");
							//@ts-ignore
							values.id = runnerInfo.id;
							const response: any =
								await RunnersApi.getInstance().editRunnerRegistryManually(
									values as any,
									token as string
								);
							const mockTimer = setTimeout(() => {
								setIsLoading(false);
								setSuccessMessage(response.message);
							}, 400);
						} catch (error) {
							console.log(`Registration error: ${error}`);
							setIsLoading(false);
							setErrorMessage(
								`Something went wrong while sending your Registration. \n ${error}`
							);
						}
					}}>
					{(formikProps) => (
						<>
							{errorMessage && (
								<ModalSingleButton
									title={"Something went wrong.."}
									icon={
										<ExclamationTriangleIcon
											className='h-6 w-6 text-red-600'
											aria-hidden='true'
										/>
									}
									message={errorMessage}
									onClose={() => {
										setErrorMessage("");
									}}
								/>
							)}
							{successMessage && (
								<ModalBase
									onClose={closeModal}
									open={!!successMessage}
									className={"py-16 mx-2 px-8 sm:px-16 sm:py-24"}
									children={<SuccessModalText message={successMessage} />}
								/>
							)}
							{isLoading && <Loading />}
							<Form className='font-montserrat'>
								<div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-1 sm:mb-10'>
									<TextInputCombo
										type='text'
										label='First Name'
										name='first_name'
										value={INITIAL_EOI_FORM_STATE.first_name}
										containerClass='sm:col-span-3 sm:row-start-1'
										onChange={formikProps.handleChange}
										disabled={true}
									/>
									<TextInputCombo
										type='text'
										label='Last Name'
										name='last_name'
										value={INITIAL_EOI_FORM_STATE.last_name}
										containerClass='sm:col-span-3 sm:row-start-1'
										onChange={formikProps.handleChange}
										disabled={true}
									/>
									<TextInputCombo
										type='email'
										label='Email'
										name='email'
										value={INITIAL_EOI_FORM_STATE.email}
										containerClass='sm:col-span-3 sm:row-start-2'
										onChange={formikProps.handleChange}
										disabled={true}
									/>
									<TextInputCombo
										type='number'
										inputMode='numeric'
										label='Target Lap Count'
										name='target_lap_count'
										//@ts-ignore
										value={INITIAL_EOI_FORM_STATE.target_lap_count}
										containerClass='sm:col-span-3 sm:row-start-2'
										onChange={formikProps.handleChange}
									/>
									<TextInputCombo
										type='time'
										label='Intended Start Time'
										name='intended_start_time'
										value={INITIAL_EOI_FORM_STATE.intended_start_time}
										containerClass='sm:col-span-3 sm:row-start-3'
										onChange={formikProps.handleChange}
									/>
								</div>

								<div className='my-6 flex items-center justify-between gap-x-6'>
									<FormCheckboxCombo
										name='agreed_to_terms'
										value='agreed_to_terms'
										containerClass='sm:col-span-full'
										label='Acceptance'
										description='* You must accept Disclaimer and Register'
										termsLink='https://bayrun-wtf.s3.ap-southeast-2.amazonaws.com/bayrun_wtf-webclient/Disclaimer.pdf'
									/>
									<button
										type='submit'
										className='px-3 py-2 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
										I Am Still Down!
									</button>
								</div>
								<div className='my-6'>
									<div className='flex flex-col border-solid border-2 p-3'>
										<article className='my-5 text-center'>
											<h1 className='text-xs sm:text-sm font-semibold tracking-widest'>
												Disclaimer for Informal Run Gathering
											</h1>
										</article>
										<article className='my-5'>
											<h2 className='text-xs text-gray-400'>
												Runner Advisory:
											</h2>
											<p className='text-xs'>
												This notice serves as an announcement for individuals
												contemplating an independent 24-hour solo running
												challenge. This is not an organised event.
											</p>
										</article>
										<article className='my-5'>
											<h2 className='text-xs text-gray-400'>
												Acknowledgment of Personal Decision:
											</h2>
											<p className='text-xs'>
												Runners are explicitly choosing to embark on a 24-hour
												solo running endeavor, understanding that this is a
												self-endorsed activity.
											</p>
										</article>
										<article className='my-5'>
											<h2 className='text-xs text-gray-400'>
												Assumption of Individual Responsibility:
											</h2>
											<p className='text-xs'>
												By opting to partake, runners acknowledge and accept
												exclusive responsibility for their safety, well-being,
												and all associated decisions.
											</p>
										</article>
										<article className='my-5'>
											<h2 className='text-xs text-gray-400'>
												No Liability Disclaimer:
											</h2>
											<p className='text-xs'>
												There are no official organisers, and, consequently, no
												liability is assumed for any incidents, injuries, or
												damages that may transpire throughout the solo running
												activity.
											</p>
										</article>
										<article className='my-5'>
											<h2 className='text-xs text-gray-400'>Acknowledgment:</h2>
											<p className='text-xs'>
												Runners acknowledge that this communication is not an
												event organization. It is a notice provided for those
												electing to pursue a 24-hour solo running challenge.
												Runners are encouraged to approach this undertaking with
												due diligence and individual responsibility.
											</p>
										</article>
									</div>
								</div>
							</Form>
						</>
					)}
				</Formik>
			</main>
		</>
	);
};
