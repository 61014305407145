import { StateTokenService } from "./state-token-service";
import { AuthApi } from "../api/auth/auth-api";
import { AxiosHeaders, AxiosResponse } from "axios";

export type AuthResponse = {
	acessToken: string;
	refreshToken: string;
};

export const AuthService = {
	login: async (email: string, password: string) => {
		const response = await AuthApi.getInstance().login(email, password);
		const authResponse = response.data as AuthResponse;
		// save access token to state + secure storage if applicable
		await StateTokenService.updateAccessToken(authResponse.acessToken);
		return response;
	},
	validateToken: async () => {
		const result = await AuthApi.getInstance().validateToken();
		return result;
	},
	validateAdminLevelAccess: async (): Promise<boolean> => {
		const result = await AuthApi.getInstance().validateAdminLevelAccess();
		return result;
	},
	logout: async () => {
		try {
			const result = await AuthApi.getInstance().logout();
			return result;
		} catch (e) {
			console.log("Logout failed");
			const response: AxiosResponse = {
				data: { message: "No valid token to logout with" },
				status: 204,
				statusText: "OK",
				config: {
					url: "",
					method: "",
					headers: {} as AxiosHeaders,
				},
				headers: {},
			};

			return Promise.resolve(response);
		}
	},
};
