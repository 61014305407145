import { useState, useEffect } from "react";
import { AuthRunnersApi } from "../api/auth/runners-details-api";
import { useNavigate } from "react-router-dom";
import { IRunner } from "../types/runner-types";
import ModalSingleButton from "../components/modal/modal-single-button";
import Loading from "../components/overlays/Loading";
// icons
import { IoStatsChartOutline } from "react-icons/io5";
import { DateTimeCalculations } from "../utils/date-time-calculations";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { LuTimer } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { CiRepeat } from "react-icons/ci";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export const Dashboard: React.FC = () => {
	const navigate = useNavigate();
	const [runners, setRunners] = useState<IRunner[]>([]);
	const [runnersNotValidated, setRunnersNotValidated] = useState<IRunner[]>([]);
	const { isLast24Hours } = DateTimeCalculations;
	const [isLast24HourNameVisible, setIsLast24HourNameVisible] = useState(false);
	const [isValidationVisible, setIsValidationVisible] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const registrationsIn24Hours = runners.filter((runner) =>
		isLast24Hours(runner.created_at)
	);

	const handleSettingsClick = () => {
		navigate("/admin/settings");
	};

	const handleNewShowButtonClick = () => {
		setIsLast24HourNameVisible(!isLast24HourNameVisible);
	};

	const handleValidateShowButtonClick = () => {
		setIsValidationVisible(!isValidationVisible);
	};

	const startTimesObject = runners.reduce(
		(acc: { [key: string]: number }, runner) => {
			const runnerStartTime = runner.intended_start_time
				.split(":")
				.slice(0, 2)
				.join(":");
			if (!acc[runnerStartTime]) {
				acc[runnerStartTime] = 1;
			} else {
				acc[runnerStartTime] += 1;
			}
			return acc; // Always return the accumulator object
		},
		{}
	);

	const lapCountObject = runners.reduce((acc: any, runner) => {
		const intendedLapCount = runner.target_lap_count;
		if (!acc[intendedLapCount]) {
			acc[intendedLapCount] = 1;
			return acc;
		}
		acc[intendedLapCount] += 1;
		return acc;
	}, {});

	useEffect(() => {
		const getRunners = async () => {
			try {
				setIsLoading(true);
				const runnersResult =
					await AuthRunnersApi.getInstance().getAllRunnerDetails();
				const runnersList: IRunner[] = runnersResult.data.data;

				const notValidatedRunners = runnersList.filter(
					(runner) => !runner.email_confirmed
				);
				setRunnersNotValidated(notValidatedRunners);
				setRunners(runnersList);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				setErrorMessage("Failed to fetch runners");
				console.error("Failed to fetch runners:", error);
			}
		};
		getRunners();
	}, []);

	const handleValidateSubmit = (runner: IRunner) => {
		const validateRunner = async () => {
			try {
				setIsLoading(true);
				const response = await AuthRunnersApi.getInstance().validateRunnerEmail(
					runner
				);
				const newNotValidatedRunnerList = runnersNotValidated.filter(
					(notValidatedRunner) => notValidatedRunner.id !== runner.id
				);
				setRunnersNotValidated(newNotValidatedRunnerList);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				setErrorMessage("Failed to validate runner");
				console.error("Failed to validate runner:", error);
			}
		};

		validateRunner();
	};

	if (isLoading) {
		return <Loading />;
	}

	if (errorMessage) {
		return (
			<ModalSingleButton
				title='Something went wrong...'
				icon={
					<ExclamationTriangleIcon
						className='h-6 w-6 text-red-600'
						aria-hidden='true'
					/>
				}
				message={errorMessage}
				onClose={() => {
					setErrorMessage("");
				}}
			/>
		);
	}

	return (
		<main className='font-montserrat flex flex-col items-center mb-5'>
			<header className='grid grid-cols-5 items-center gap-20 sm:my-20'>
				<button
					onClick={() => {
						navigate("/admin/runners");
					}}
					className='col-start-2 sm:col-start-2 md:col-start-3 tracking-widest text-gray-400 font-light text-lg sm:text-2xl sm:mr-14'>
					RUNNERS
				</button>
				<IoSettingsOutline
					onClick={handleSettingsClick}
					className='col-start-4 text-gray-400 w-10 h-10 cursor-pointer'
				/>
			</header>

			<section className='grid grid-cols-1 w-4/5 sm:w-full sm:grid-cols-2 lg:grid-cols-4 gap-5 my-5'>
				<div className='flex flex-col gap-5 sm:col-span-2 items-start border border-2 border-gray-200 p-5 rounded-lg'>
					<header className='flex justify-between w-full'>
						<h1 className='text-gray-400 font-semibold'>TOTAL</h1>
						<IoStatsChartOutline />
					</header>
					<p className='text-gray-600 font-bold text-xl sm:text-3xl'>
						{runners.length}{" "}
						<span className='text-sm text-gray-400 font-normal'>
							including not validated (if any)
						</span>
					</p>
				</div>
				<div className='flex flex-col gap-5 items-start border border-2 border-gray-200 p-5 rounded-lg'>
					<header className='flex justify-between w-full'>
						<h1 className='text-gray-400 font-semibold'>NEW</h1>
						<HiOutlineCalendarDays />
					</header>
					<article className='flex gap-5 justify-between '>
						<div className='flex gap-5'>
							<p className='text-gray-600 font-bold text-xl sm:text-3xl'>
								{registrationsIn24Hours.length}
							</p>
							<p className='text-gray-400 font-light tracking-widest text-sm sm:text-normal'>
								last 24 hours
							</p>
						</div>
						<article className='flex gap-5 items-center justify-center'>
							{isLast24HourNameVisible ? (
								<article>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleNewShowButtonClick();
										}}
										className='text-gray-400 font-semibold tracking-widest text-normal sm:text-lg'>
										HIDE
									</button>
									{registrationsIn24Hours.map((runner, index) => (
										<div
											className='text-gray-400 font-normal tracking-widest text-sm lg:text-normal'
											key={index}>
											<p>
												{runner.first_name} {runner.last_name}
											</p>
											<p>{new Date(runner.created_at).toLocaleDateString()}</p>
										</div>
									))}
								</article>
							) : (
								<button
									onClick={(e) => {
										e.preventDefault();
										handleNewShowButtonClick();
									}}
									className='text-gray-400 font-semibold tracking-widest text-normal sm:text-lg'>
									SHOW
								</button>
							)}
						</article>
					</article>
				</div>
				<div className='flex flex-col gap-5 items-start border border-2 border-gray-200 p-5 rounded-lg'>
					<header className='flex justify-between w-full'>
						<h1 className='text-gray-400 font-semibold'>WAITING VALIDATION</h1>
						<HiOutlineCalendarDays />
					</header>
					<article className='flex gap-5 justify-between '>
						<div className='flex gap-5'>
							<p className='text-gray-600 font-bold text-xl sm:text-3xl'>
								{runnersNotValidated.length}
							</p>
						</div>
						<article className='flex gap-5 items-center justify-center'>
							{isValidationVisible ? (
								<article>
									<button
										onClick={(e) => {
											e.preventDefault();
											handleValidateShowButtonClick();
										}}
										className='text-gray-400 font-semibold tracking-widest text-normal sm:text-lg'>
										HIDE
									</button>
									{runnersNotValidated.map((runner, index) => (
										<div
											className='text-gray-400 font-normal tracking-widest text-sm lg:text-normal'
											key={index}>
											<p>
												{runner.first_name} {runner.last_name}
											</p>
											<p>{new Date(runner.created_at).toLocaleDateString()}</p>
											<button
												onClick={(e) => {
													e.preventDefault();
													handleValidateSubmit(runner);
												}}
												className='px-3 py-2 mt-1 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
												Validate
											</button>
										</div>
									))}
								</article>
							) : (
								<button
									onClick={(e) => {
										e.preventDefault();
										handleValidateShowButtonClick();
									}}
									className='text-gray-400 font-semibold tracking-widest text-normal sm:text-lg'>
									SHOW
								</button>
							)}
						</article>
					</article>
				</div>
				<div className='lg:col-span-2 flex flex-col gap-5 items-start border border-2 border-gray-200 p-5 rounded-lg'>
					<header className='flex justify-between w-full'>
						<h1 className='text-gray-400 font-semibold'>START</h1>
						<LuTimer />
					</header>
					<article className='w-full flex flex-col gap-5 items-start'>
						{Object.entries(startTimesObject).map(
							([startTime, count], index) => (
								<div className='flex flex-wrap items-center' key={index}>
									<span className='text-gray-600 font-bold text-xl sm:text-3xl mr-5 sm:mr-2'>
										{startTime}
									</span>
									<span className='text-gray-400 font-light tracking-widest text-sm sm:text-normal'>
										{count} runners
									</span>
								</div>
							)
						)}
					</article>
				</div>
				<div className='lg:col-span-2 border border-2 border-gray-200 p-5 rounded-lg'>
					<header className='flex justify-between w-full'>
						<h1 className='text-gray-400 font-semibold'>LAP</h1>
						<CiRepeat />
					</header>
					<article className='w-full flex flex-col gap-5 items-start'>
						{Object.entries(lapCountObject).map(([lapCount, count], index) => (
							<div className='flex  items-center gap-10' key={index}>
								<span className='text-gray-600 font-bold text-xl sm:text-3xl'>
									{lapCount}
								</span>
								<span className='text-gray-400 font-light tracking-widest text-sm sm:text-normal'>
									{count as React.ReactNode} runners
								</span>
							</div>
						))}
					</article>
				</div>
			</section>
		</main>
	);
};
