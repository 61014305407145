import React from "react";
import { useFormikContext } from "formik";
import { useLocation } from "react-router-dom";

type FormCheckboxProps = {
	label: string;
	description: string;
	name: string;
	value: string;
	checked?: boolean;
	containerClass: string;
	onChange?: (e: boolean) => void;
	termsLink?: string;
};
export const FormCheckboxCombo: React.FC<FormCheckboxProps> = (props) => {
	const { setFieldValue } = useFormikContext();
	const location = useLocation();

	return (
		<fieldset className={props.containerClass}>
			<legend className='sr-only'>Notifications</legend>
			<div className='space-y-5'>
				<div className='relative flex items-start'>
					<div className='flex h-6 items-center'>
						<input
							onChange={async (e) => {
								location.pathname !== "/edit/register" &&
									props.onChange &&
									props.onChange(e.target.checked);
								location.pathname !== "/edit/register" &&
									(await setFieldValue(props.name, e.target.checked));
							}}
							checked={
								location.pathname === "/edit/register" ? true : props.checked
							}
							id='agreed_to_terms'
							aria-describedby='comments-description'
							name='agreed_to_terms'
							type='checkbox'
							className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
						/>
					</div>
					<div className='ml-3 text-sm leading-6'>
						<label
							htmlFor='agreed_to_terms'
							className='font-medium text-gray-900 hover:text-brand-primary underline'
							onClick={() => {
								if (props.termsLink) {
									window.open(props.termsLink, "_blank", "noopener,noreferrer");
								}
							}}>
							{props.label}
						</label>

						<p id='agreed_to_terms' className='text-gray-500'>
							{props.description}
						</p>
					</div>
				</div>
			</div>
		</fieldset>
	);
};

export default FormCheckboxCombo;
