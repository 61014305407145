import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import TextInputCombo from "../layouts/ui/Text-input-combo";
import * as yup from "yup";
import { AuthEventsApi } from "../api/auth/events-details-api";
import Loading from "../components/overlays/Loading";
import ModalSingleButton from "../components/modal/modal-single-button";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import ToggleInputCombo from "../layouts/ui/toggle-input-combo";

const REG_SETTING_VALIDATION = yup.object().shape({
	max_registration_number: yup
		.number()
		.required("Required")
		.typeError("Must be a number")
		.positive()
		.integer()
		.max(150, "Cannot be bigger than 150"),
});

export const AdminSettings: React.FC = () => {
	const [initialValues, setInitialValues] = useState({
		max_registration_number: "",
		is_force_email_confirmation: false,
	});
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const formikRef = useRef(null);

	useEffect(() => {
		const getEventDetails = async () => {
			setIsLoading(true);
			try {
				const eventDetails = await AuthEventsApi.getInstance().getEventDetails(
					"bayrunWTF"
				);
				const maxRegLimit =
					eventDetails.data.eventDetails.max_registration_number;
				const isForceEmail =
					eventDetails.data.eventDetails.is_force_email_confirmation;
				setInitialValues({
					max_registration_number: maxRegLimit.toString(),
					is_force_email_confirmation: isForceEmail,
				});
			} catch (error) {
				console.error("Failed to fetch event details:", error);
			} finally {
				setIsLoading(false);
			}
		};

		getEventDetails();
	}, []);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<main className='h-[100vh] font-montserrat text-center mx-auto w-4/5 sm:w-3/5'>
			{errorMessage && (
				<ModalSingleButton
					title={"Something went wrong.."}
					icon={
						<ExclamationTriangleIcon
							className='h-6 w-6 text-red-600'
							aria-hidden='true'
						/>
					}
					message={errorMessage}
					onClose={() => {
						setErrorMessage("");
					}}
				/>
			)}
			<section className='my-5'>
				<h1 className='tracking-widest text-gray-400 font-light text-lg sm:text-2xl mb-10 sm:mb-20'>
					Maximum Registration Number
				</h1>
				<Formik
					innerRef={formikRef}
					initialValues={initialValues}
					validationSchema={REG_SETTING_VALIDATION}
					enableReinitialize // This is important to reinitialise the form when initialValues change
					onSubmit={async (values) => {
						try {
							setIsLoading(true);
							const eventDetails =
								await AuthEventsApi.getInstance().updateEventRegLimit(
									"bayrunWTF",
									Number(values.max_registration_number),
									values.is_force_email_confirmation
								);
							const maxRegLimit =
								eventDetails.data.eventDetails.max_registration_number;
							const isForceEmail =
								eventDetails.data.eventDetails.is_force_email_confirmation;
							setInitialValues({
								max_registration_number: maxRegLimit.toString(),
								is_force_email_confirmation: isForceEmail,
							});
							setIsLoading(false);
						} catch (error) {
							const axiosError = error as AxiosError;
							setIsLoading(false);
							if (axiosError && axiosError.response) {
								console.log(axiosError.response.data);
								setErrorMessage(
									"Couldn't change the settings, please try again later..."
								);
							} else {
								// Handle non-Axios errors
								console.log(error);
								setErrorMessage(
									"An unexpected error occurred. Please try again later."
								);
							}
						}
					}}>
					{({ handleChange }) => (
						<Form className='flex flex-col items-end sm:w-full sm:items-center'>
							<section className='grid grid-cols-2 sm:grid-cols-5 gap-1 w-full'>
								<TextInputCombo
									type='text'
									value=''
									label='Max Reg Number'
									name='max_registration_number'
									containerClass='sm:col-start-1 sm:col-span-2 w-full tracking-widest text-sm font-montserrat text-gray-400 text-lg sm:text-2xl'
									onChange={handleChange}
								/>
								<ToggleInputCombo
									name='is_force_email_confirmation'
									label='Require Email Verification'
									containerClass='sm:col-start-4 sm:col-span-2'
								/>
							</section>
							<button
								type='submit'
								className='px-3 py-2 mt-20 border-2 rounded-xl border-gray-200 hover:bg-[#13294B] hover:bg-opacity-75'>
								Update Settings
							</button>
						</Form>
					)}
				</Formik>
			</section>
		</main>
	);
};
