import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth-slice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";

// Configurations for persisting specific reducers
const authPersistConfig: PersistConfig<any> = {
	key: "auth",
	storage,
	whitelist: ["accessToken"], // specify properties you want to persist, remove this line if you want to persist the whole state
};

// User persistReducer to wrap your reducers
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

// Store configuration with persisted reducers
export const store = configureStore({
	reducer: {
		auth: persistedAuthReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ["persist/PERSIST"],
			},
		}),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
