import React, { useEffect, useRef, useState } from "react";
import ModalSingleButton from "../../components/modal/modal-single-button";
import { RiUserForbidLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuth } from "../../providers/auth-provider";
import Loading from "../../components/overlays/Loading";
import { Form, Formik } from "formik";
import TextInputCombo from "../../layouts/ui/Text-input-combo";
import { AuthService } from "../../services/auth-service";
import { AxiosError } from "axios";

const INITAL_FORM_STATE = {
	email: "",
	password: "",
};

const FORM_VALIDATION = yup.object().shape({
	email: yup.string().email().required(),
	password: yup.string().required(),
});

export const Login: React.FC = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const formikRef = useRef(null);
	const navigate = useNavigate();
	const { onLogin, accessToken } = useAuth();

	useEffect(() => {
		const loadToken = async () => {
			if (accessToken) {
				try {
					const result = await AuthService.validateAdminLevelAccess();
					if (result) {
						navigate("/admin/dashboard");
					}
				} catch (error) {
					const axiosError = error as any;
					if (axiosError && axiosError.response) {
						const errorMessage = axiosError.response.data.error;
						console.error(errorMessage);
						setErrorMessage("Your session has expired, please log in again.");
					} else {
						// Handle non-Axios errors or other kinds of errors
						setErrorMessage("An unexpected error occurred.");
					}
				}
			}
		};
		loadToken();
	}, [accessToken, navigate]);

	return (
		<>
			{isLoading && <Loading />}
			{errorMessage && (
				<ModalSingleButton
					title='Something went wrong...'
					icon={<RiUserForbidLine />}
					message={errorMessage}
					onClose={() => setErrorMessage("")}
				/>
			)}
			<main className='font-montserrat h-[70vh] flex flex-col justify-center items-center '>
				<section className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-sm'>
					<h2 className='text-2xl text-center text-gray-400 font-light tracking-widest mb-6'>
						Admin Login
					</h2>
					<Formik
						innerRef={formikRef}
						initialValues={INITAL_FORM_STATE}
						validationSchema={FORM_VALIDATION}
						onSubmit={async (values, { resetForm }) => {
							try {
								setIsLoading(true);
								const response = await onLogin!(values.email, values.password);
							} catch (error) {
								const errorMessage = (error as AxiosError).response;
								console.log("error: ", errorMessage?.data);
								if (errorMessage?.status === 403) {
									setErrorMessage(
										"Access not granted - ensure you have validated your email"
									);
									return;
								}
								setErrorMessage("Your credentials are invalid");
							} finally {
								setIsLoading(false);
							}
						}}>
						{(formikProps) => (
							<>
								<Form>
									<TextInputCombo
										type='text'
										label='Email'
										name='email'
										autoComplete='email'
										value={INITAL_FORM_STATE.email}
										containerClass=''
										onChange={formikProps.handleChange}
									/>
									<TextInputCombo
										type={showPassword ? "text" : "password"}
										label='Password'
										name='password'
										autoComplete='current-password'
										value={INITAL_FORM_STATE.password}
										containerClass=''
										onChange={formikProps.handleChange}
									/>
									<div className='flex justify-between'>
										<button
											className='mt-5 px-3 py-2 border-2 rounded-xl border-gray-200 bg-gray-100 hover:bg-[#13294B] hover:bg-opacity-75'
											type='submit'>
											<p className='text-[#13294B]'>Login</p>
										</button>
										<label htmlFor='show' className='flex items-center mt-2'>
											<input
												type='checkbox'
												id='show'
												name='show'
												className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
												onChange={() => setShowPassword(!showPassword)}
											/>
											<span className='ml-2 text-sm text-gray-600 dark:text-gray-400'>
												Show Password
											</span>
										</label>
									</div>
								</Form>
							</>
						)}
					</Formik>
				</section>
			</main>
		</>
	);
};
