import React from "react";
import { PublicTopNavigation } from "./public-top-navigation";

type ContainerProps = {
	children: React.ReactNode;
	handleSummaryToggle: () => void;
};

export const PublicBodyContentContainer: React.FC<ContainerProps> = (props) => {
	return (
		<>
			<PublicTopNavigation handleSummaryToggle={props.handleSummaryToggle} />
			<div className='bg-gray-50 font-montserrat pt-6 px-3 sm:px-8 lg:px-12 overflow-hidden'>
				{props.children}
			</div>
		</>
	);
};

export default PublicBodyContentContainer;
