import { EditorState } from "lexical";
import { BaseApi } from "../base-api";
import { AxiosResponse } from "axios";

export class AuthEmailApi extends BaseApi {
	protected static instance: AuthEmailApi;

	private constructor() {
		super();
	}

	public static getInstance(): AuthEmailApi {
		if (!this.instance) {
			this.instance = new AuthEmailApi();
		}

		return this.instance;
	}

	async sendEmail(payload: {
		emailList: string[];
		email: EditorState;
		subject: string;
	}): Promise<AxiosResponse> {
		const requestBody = {
			email_list: payload.emailList,
			email_body: payload.email,
			email_subject: payload.subject,
		};
		const response = await this.authedApi.post(
			`${this.BASE_URL}admin/email`,
			requestBody,
			{ withCredentials: true }
		);

		return response;
	}

	async sendRegistrationConfirmationEmail(payload: {
		emailList: string[];
		email: EditorState;
		subject: string;
	}): Promise<AxiosResponse> {
		const requestBody = {
			email_list: payload.emailList,
			email_body: payload.email,
			email_subject: payload.subject,
		};
		const response = await this.authedApi.post(
			`${this.BASE_URL}admin/email/confirm/registration`,
			requestBody,
			{ withCredentials: true }
		);

		return response;
	}

	async getEmailHistory(): Promise<AxiosResponse> {
		const response = await this.authedApi.get(`${this.BASE_URL}admin/email`, {
			withCredentials: true,
		});

		return response;
	}
}
