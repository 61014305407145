import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/auth-provider";
import { AuthService } from "../services/auth-service";
import Loading from "../components/overlays/Loading";

export const AdminProtectedRoute = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isTokenValidated, setIsTokenValidated] = useState(false);
	const navigate = useNavigate();
	const { setAccessToken } = useAuth();

	useEffect(() => {
		const validateToken = async () => {
			try {
				// validate token and access level
				await AuthService.validateAdminLevelAccess();
				setIsTokenValidated(true);
			} catch (error) {
				console.log(
					"Problem occurred checking token validity in protected route"
				);
				setIsTokenValidated(false);
				setAccessToken!("");
				navigate("/login");
				return;
			}

			validateToken();
		};
	}, [navigate]);

	if (!isTokenValidated) {
		// Render a loading state or a different component if token validation is in progress
		{
			isLoading && <Loading />;
		}
	}

	return <Outlet />;
};
