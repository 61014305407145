import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
	accessToken: string;
}

const initialState: AuthState = {
	accessToken: "",
};

// NOTE: slices are only actions to take on dispatch - you get info directly from store
const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		updateAccessToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
	},
});

export const { updateAccessToken } = authSlice.actions;
export default authSlice.reducer;
