import { CircularProgress } from "@mui/material";

const Loading = () => {
	return (
		<div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
			<CircularProgress color='inherit' />
		</div>
	);
};

export default Loading;
