import React from "react";
import { useField } from "formik"; // <-- Import useField from Formik

interface ITextInputCombo {
	label: string;
	name: string;
	type: string;
	inputMode?: any;
	value: string;
	placeHolder?: string;
	containerClass: string;
	autoComplete?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

export const TextInputCombo: React.FC<ITextInputCombo> = (props) => {
	const containerClassName = `${props.containerClass}`;

	const [field, meta] = useField(props.name); // <-- useField hook provides field data and meta data
	return (
		<div className={containerClassName}>
			<label
				htmlFor={props.name}
				className='block text-sm font-montserrat leading-6 text-gray-900'>
				{props.label}
			</label>
			<div className={`mt-2 ${props.disabled && "bg-gray-200"}`}>
				<input
					placeholder={props.placeHolder}
					autoComplete={props.autoComplete}
					type={props.type}
					{...(props.inputMode && { inputMode: props.inputMode })}
					{...field} // <-- spread the field object to wire up the input
					className={`pl-3 block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
						meta.touched && meta.error ? "ring-2 ring-red-500" : ""
					}`}
					onChange={props.onChange}
					disabled={props.disabled}
				/>
				{/* Render validation error */}
				{meta.touched && meta.error ? (
					<div className='text-red-500 text-sm mt-1'>{meta.error}</div>
				) : null}
			</div>
		</div>
	);
};

export default TextInputCombo;
