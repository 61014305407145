import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { EditorState } from "lexical";
import { useEffect } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";

export const MyOnChangePlugin = (props: {
	onChange: (editorState: any) => void;
}) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor) {
			console.error("Lexical editor instance not found.");
			return;
		}

		return editor.registerUpdateListener(({ editorState }) => {
			editorState.read(() => {
				const htmlString = $generateHtmlFromNodes(editor, null);
				props.onChange(htmlString);
			});
		});
	}, [editor, props.onChange]);

	return null;
};
