import { useEffect, useState } from "react";
import { RunnersApi } from "../../api/runners-api";
import { ScrollToTopButton } from "../../components/ScrollButton/ScrollButton";
import { QRCodeSVG } from "qrcode.react";
import Loading from "../../components/overlays/Loading";
import { FaShieldAlt, FaHourglassStart } from "react-icons/fa";

interface RunnersProps {
	first_name: string;
	last_name: string;
	target_lap_count: number;
	qr_code_identifier: string;
	confirmed_registry: boolean;
}

export const Leaderboard: React.FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [copyRunners, setCopyRunners] = useState<RunnersProps[]>([]); // Store the original list
	const [runners, setRunners] = useState<RunnersProps[]>([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			try {
				const runnersData: RunnersProps[] =
					await RunnersApi.getInstance().getAllRunners();
				setRunners(runnersData);
				setCopyRunners(runnersData);
				const falseDelay = setTimeout(() => {
					setIsLoading(false);
				}, 500);
			} catch (error) {
				console.error("Error fetching runners:", error);
				setIsLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const filteredRunners = copyRunners.filter((runner) => {
			// Combine first name and last name with a space, then convert to lowercase
			const fullName = [runner.first_name, runner.last_name]
				.join(" ")
				.toLowerCase();
			// Convert the search term to lowercase without altering spaces
			const searchTerm = search.toLowerCase();
			// Check if the full name includes the search term
			return fullName.includes(searchTerm);
		});

		setRunners(filteredRunners);
	}, [search, copyRunners]);

	return (
		<>
			{isLoading && <Loading />}
			{runners.length === 0 ? (
				<section className='flex flex-col text-center items-center h-[100vh]'>
					<article className='flex flex-col items-center w-full mb-5'>
						<h1 className='traching-widest font-light text-lg sm:text-xl mb-4 text-center text-gray-500'>
							Search Runners
						</h1>
						<input
							onChange={(e) => setSearch(e.target.value)}
							className='border border-2 border-gray-200 rounded-lg p-3 w-full sm:w-3/5 mb-5'
						/>
					</article>
					<article
						className='
							py-10
							px-5
					  		text-white
					  		bg-gray-400
					  		bg-opacity-45
					  		backdrop-blur-md
					  		rounded-3xl
					  		shadow-2xl
							lg:w-[50%]
							text-xl
							sm:text-2xl
        '>
						No Runners Found Under That Name
					</article>
				</section>
			) : (
				<section className='my-1 sm:my-10'>
					<article className='flex flex-col items-center w-full mb-5'>
						<h1 className='traching-widest font-light text-lg sm:text-xl mb-4 text-center text-gray-500'>
							Search Runners
						</h1>
						<input
							onChange={(e) => setSearch(e.target.value)}
							className='border border-2 border-gray-200 rounded-lg p-3 w-full sm:w-3/5 mb-5'
						/>
					</article>
					<ol className='flex flex-col justify-center items-start text-center'>
						{/* Title Row */}
						<li className='w-full'>
							<article className='font-semibold tracking-widest leading-10 border-b-2 border-gray-400 text-sm sm:text-xl grid grid-cols-3 gap-1 my-1'>
								<h1 className='ml-5'>No</h1>
								<h1>Name</h1>
								<h1 className='pb-1 sm:pb-4'>Target Lap</h1>
							</article>
						</li>

						{/* Data Rows */}
						{runners.map((runner, index) => (
							<li key={index} className='w-full'>
								<article className='grid grid-cols-3 gap-3 border-b-2 py-2.5 shadow-base items-center'>
									<p className='font-semibold'>{index + 1}</p>
									<h1 className='text-sm sm:text-lg'>
										{runner.first_name} {runner.last_name}
									</h1>
									<h1 className='text-sm sm:text-lg'>
										{runner.target_lap_count}
										<span className='text-gray-400 ml-3 sm:ml-5 text-xs'>
											{Number(runner.target_lap_count) * 6.8}km
										</span>
									</h1>
								</article>
							</li>
						))}
					</ol>
					<ScrollToTopButton />
				</section>
			)}
		</>
	);
};
