import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BayVideo from "../../../assets/the_bay_run_timelapse.mp4";
import { CountdownCircle } from "../../../components/CountdownCircle";
import { InfoApi } from "../../../api/info-api";

export const VideoBackground: React.FC = () => {
	const [timeLeft, setTimeLeft] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const location = useLocation();
	const navigate = useNavigate();
	const videoRef = useRef<HTMLVideoElement>(null);
	const countdownTimerRef = useRef<NodeJS.Timeout | null>(null);
	const startTimeRef: React.MutableRefObject<Date> = useRef<Date>(new Date());

	const updateCountdown = () => {
		const now = new Date(
			startTimeRef.current.getTime() +
				Date.now() -
				startTimeRef.current.getTime()
		);
		const targetTime = new Date("2025-03-29T08:00:00+10:00");
		const timeLeftMs = targetTime.getTime() - now.getTime();
		if (timeLeftMs > 0) {
			const days = Math.floor(timeLeftMs / (1000 * 60 * 60 * 24));
			const hours = Math.floor((timeLeftMs / (1000 * 60 * 60)) % 24);
			const minutes = Math.floor((timeLeftMs / (1000 * 60)) % 60);
			const seconds = Math.floor((timeLeftMs / 1000) % 60);
			setTimeLeft({ days, hours, minutes, seconds });
		} else {
			setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
			if (countdownTimerRef.current) {
				clearInterval(countdownTimerRef.current);
			}
		}
	};

	const handleEOIButtonPress = () => {
		navigate("/register");
	};

	useEffect(() => {
		InfoApi.getInstance()
			.getCurrentTime()
			.then((data) => {
				// @ts-ignore
				startTimeRef.current = new Date(data.utc_datetime);
				countdownTimerRef.current = setInterval(updateCountdown, 1000);
			})
			.catch((error) => console.error(error));

		return () => {
			if (countdownTimerRef.current) {
				clearInterval(countdownTimerRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (location.pathname === "/" && videoRef.current) {
			videoRef.current.playbackRate = 0.3;
		}
	}, [location.pathname]);

	return location.pathname === "/" ? (
		<div className='flex flex-col items-center h-0'>
			<video
				ref={videoRef}
				autoPlay={true}
				loop={true}
				muted={true}
				playsInline={true}
				className='absolute top-0 w-full lg:h-full h-[100vh] min-h-[700px] sm:min-h-[800px] object-cover'>
				<source src={BayVideo} type='video/mp4' />
			</video>
			<article
				className='
					  relative
					  z-10
					  flex
					  flex-col
					  items-center
					  gap-5 
					  text-white
					  p-5
					  sm:mt-20
					  lg:mt-4
					  lg:p-16
					  bg-gray-600
					  bg-opacity-25
					  backdrop-blur-sm
					  rounded-3xl
					  shadow-2xl
					  w-[90vw]
					  lg:w-2/5
					  lg:min-w-[800px]
        '>
				<h1 className='font-semibold text-base sm:text-3xl lg:text-4xl tracking-widest mb-4 text-center text-white'>
					BayRun.WTF's Unofficial Ultra Adventure
				</h1>
				<p className='font-normal text-sm sm:text-lg lg:text-xl leading-loose tracking-wider'>
					Buckle up, mates! This isn't your average run around the Bay – it's
					the unofficial, underground challenge that'll have your neighbors
					peeking through the curtains wondering if you've lost your marbles.
				</p>
				<section className='flex gap-4 lg:gap-8 justify-center items-center'>
					<CountdownCircle label='Days' value={timeLeft.days} />
					<CountdownCircle label='Hours' value={timeLeft.hours} />
					<CountdownCircle label='Minutes' value={timeLeft.minutes} />
					<CountdownCircle label='Seconds' value={timeLeft.seconds} />
				</section>
				<button
					onClick={handleEOIButtonPress}
					className='font-semibold border-4 rounded-2xl p-5 text-base sm:text-2xl bg-gray-200
					bg-opacity-25 hover:bg-[#13294B] hover:bg-opacity-75'>
					Register Interest
				</button>
			</article>
		</div>
	) : null;
};
